<template>
  <div class="mt-[40px] flex flex-col md:flex-row justify-between items-center">
    <p v-if="withResults" class="mb-[40px] md:mb-0">
      <strong>Showing {{ visibleItems }}</strong> out of {{ totalItems }} items
    </p>
    <div
      class="flex rounded-sm w-fit"
      :class="{
        'border border-grey-4 divide-grey-4 divide-x ': variant === 'basic',
      }">
      <button
        :disabled="currentPage === 1"
        class="flex justify-center items-center cursor-pointer"
        :class="{
          '!cursor-not-allowed': currentPage === 1,
          '!bg-transparent': currentPage === 1 && variant === 'basic',
          '!bg-blue-1': currentPage === 1 && variant === 'pill',
          'px-3 py-2 hover:bg-grey-4 rounded-l-sm bg-white': variant === 'basic',
          'rounded-xl px-6 bg-blue-1': variant === 'pill',
        }"
        @click="handleClick(0)">
        <ArrowSide
          v-if="!slot?.leftArrow"
          :fill="[currentPage === 1 ? iconFillColorDisabled : iconFillColor]"
          class="transform rotate-180" />
        <slot name="leftArrow" />
      </button>
      <p
        class="px-4 py-2 cursor-pointer text-otivo-blue font-bold"
        :class="{
          'text-white !bg-otivo-blue': link === currentPage && variant === 'basic',
          '!text-blue-1 bg-transparent': link === currentPage && variant === 'pill',
          'cursor-default': link === '...',
          'rounded-xl text-otivo-blue hover:bg-transparent': variant === 'pill',
          'bg-white hover:bg-grey-4 text-otivo-blue': variant === 'basic',
        }"
        :key="link"
        v-for="link in links"
        @click="handleClick(link)">
        {{ link }}
      </p>
      <button
        :disabled="currentPage === totalPages"
        class="flex justify-center items-center cursor-pointer"
        :class="{
          '!cursor-not-allowed': currentPage === totalPages,
          '!bg-transparent': currentPage === totalPages && variant === 'basic',
          'rounded-xl px-6 bg-blue-1': variant === 'pill',
          'px-3 py-2  hover:bg-grey-4 rounded-r-sm bg-white': variant === 'basic',
        }"
        @click="handleClick(-1)">
        <ArrowSide
          v-if="!slot?.rightArrow"
          :fill="[currentPage === totalPages ? iconFillColorDisabled : iconFillColor]" />
        <slot name="rightArrow" />
      </button>
    </div>
    <slot name="page-indicator" :current-page="currentPage" :total-pages="totalPages" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, useSlots } from 'vue'
import ArrowSide from '@/components/icons/ArrowSide.vue'

interface Props {
  totalPages: number
  withResults?: boolean
  visibleItems?: number
  totalItems?: number
  variant?: 'basic' | 'pill'
}

const props = withDefaults(defineProps<Props>(), {
  withResults: false,
  visibleItems: 0,
  totalItems: 0,
  variant: 'basic',
})
const emit = defineEmits(['pageChange'])
const slot = useSlots()

const MAX_LINKS = 7
const currentPage = ref(1)

const iconFillColor = computed(() => (props.variant === 'basic' ? '#0064FF' : '#FFF'))
const iconFillColorDisabled = computed(() => (props.variant === 'basic' ? '#D1D5DB' : '#0064FF'))

const links = computed(() => {
  const middle = Math.floor(MAX_LINKS / 2)

  if (props.totalPages < MAX_LINKS) {
    return generateLinksLessThanMax(props.totalPages)
  }
  if (currentPage.value <= middle) {
    return generateLinksLeft(middle)
  }
  if (currentPage.value > props.totalPages - middle) {
    return generateLinksRight(middle)
  }

  return [
    1,
    '...',
    currentPage.value - 1,
    currentPage.value,
    currentPage.value + 1,
    '...',
    props.totalPages,
  ]
})

const generateLinksLessThanMax = (totalPages: number): Array<number> => {
  const links = []
  for (let i = 0; i < totalPages; i++) {
    links[i] = i + 1
  }
  return links
}

const generateLinksLeft = (middle: number): Array<number> => {
  const left = []
  for (let i = 0; i <= middle; i++) {
    left[i] = i + 1
  }
  return [...left, '...', props.totalPages]
}

const generateLinksRight = (middle: number): Array<number> => {
  const right = []
  for (let i = 0; i <= middle; i++) {
    right[i] = props.totalPages - middle + i
  }
  return [1, '...', ...right]
}

const handleClick = (pageNumber: number | string) => {
  if (typeof pageNumber === 'string') return
  if (pageNumber === 0) {
    currentPage.value -= 1
  } else if (pageNumber === -1) {
    currentPage.value += 1
  } else {
    currentPage.value = pageNumber
  }

  emit('pageChange', currentPage.value)
}
</script>

<style></style>
