import getApiInstance from '@/services/Api'
import { AxiosResponse } from 'axios'
import { ClientUser, TermDepositResponse } from '@/types/CallcentrePortalTypes.ts'
import { SuperWidgetData } from '@/store/pinia/IntrafundAdviceStore.ts'

const api = getApiInstance()

/**
 * TODO: Refactor this to abstract from 'CFS'
 */

export default {
  confirmMemberDetails(payload) {
    return api.Post('/superfund/users/confirm', payload)
  },

  resendClientSMS(payload) {
    return api.Post('/superfund/users/confirm/retrysms', payload)
  },

  /**
   * Becomes create/update/deleteManagedUser
   * @param payload
   */
  updateSuperFundClient(payload: Record<string, unknown>) {
    return api.Put(`/modularised_advice/users`, payload)
  },

  updateSuperFundClientMemberId(payload: Record<string, unknown>) {
    return api.Put(`/modularised_advice/users/update/member-id`, payload)
  },

  deleteSuperFundClient(auth0id: string) {
    return api.Delete(`/modularised_advice/users/${auth0id}`)
  },

  sendGuidanceOnboardEmail(payload: Record<string, unknown>) {
    return api.Post('/modularised_advice/users/onboard_email', payload)
  },

  /**
   * -> searchUsers('cfs', 'clientA', 'clientB')?
   * @param payload
   */
  getClients(payload: Record<string, unknown>) {
    return api.Post('/modularised_advice/search-users', payload)
  },

  getModularisedSuper(superId: number | string) {
    return api.Get<AxiosResponse<Record<string, unknown>>>(`/modularised_advice/supers/${superId}`)
  },

  updateModularisedSuper(payload: Record<string, unknown>, superId: number | string) {
    return api.Put(`/modularised_advice/supers/${superId}`, payload)
  },

  sendIntrafundAdviceActionEmail(payload: Record<string, unknown>, superId: number | string) {
    return api.Put(`/modularised_advice/supers/email/${superId}`, payload)
  },

  deleteModularisedSuper(superId: number | string) {
    return api.Delete(`/modularised_advice/supers/${superId}`)
  },

  getModularisedSuperAdvice(payload: Record<string, unknown>) {
    return api.Post<AxiosResponse<SuperWidgetData>>(
      '/modularised_advice/advice/intrafundAdvice',
      payload,
    )
  },

  generateSuperFundSoa(payload: { auth0id: string }) {
    return api.Post('/superfund/soa/generate', payload, { timeout: 180000 })
  },

  downloadSoA(payload: { clientAccountId?: string | number; soaId?: number }) {
    return api.Post('/superfund/soa/download', payload)
  },

  sendLegalWelcomeEmail(payload: Record<string, unknown>) {
    return api.Post('/modularised_advice/legal_welcome_email', payload)
  },

  /*
   * Flags -> getUserFlags()?
   */
  getConsentFlag(accountId: number | string) {
    return api.Get(`/modularised_advice/users/consent/${accountId}`)
  },

  setConsentFlag(payload: Record<string, unknown>) {
    return api.Post(`/modularised_advice/users/consent/${payload.account_id}`, payload)
  },

  renewConsentFlag(payload: Record<string, unknown>) {
    return api.Post(`/modularised_advice/users/consent/renew/${payload.account_id}`, payload)
  },

  getTermDepositConsentFlag(superId: number | string) {
    return api.Get<TermDepositResponse>(`/modularised_advice/users/term-deposit-consent/${superId}`)
  },

  setTermDepositConsentFlag(payload: { superId: string; consented: boolean }) {
    return api.Post<TermDepositResponse>(
      `/modularised_advice/users/term-deposit-consent/${payload.superId}`,
      {
        consented: payload.consented,
      },
    )
  },

  logDisclaimerConsent(superId: number | string) {
    return api.Post(`/modularised_advice/users/disclaimer-consent/${superId}`)
  },

  postIntrafundAdviceRequest(payload: Record<string, unknown>, superId: number | string) {
    return api.Post(`/modularised_advice/advice/request/${superId}`, payload)
  },

  updateIntrafundAdviceRequest(payload: Record<string, unknown>, superId: number | string) {
    return api.Put<AxiosResponse>(`/modularised_advice/advice/request/${superId}`, payload)
  },

  readClientData(payload: { auth0Id: string }): Promise<AxiosResponse<ClientUser>> {
    return api.Post('/modularised_advice/readSuperFundClient', payload) as Promise<
      AxiosResponse<ClientUser>
    >
  },

  fetchBackendReports() {
    return api.Get('/modularised_advice/reports')
  },

  downloadSuperFundBackendReport(link) {
    return api.Get(`/modularised_advice/reports/${link}`)
  },

  getLastNumFinancialYears(years: number) {
    return api.Get(`/modularised_advice/last_num_financial_years/${years}`)
  },

  getLastFiveConcessionalContributions(accountId: string) {
    return api.Get(`/modularised_advice/concessional_contributions/${accountId}`)
  },

  getLastThreeNonConcessionalContributions(accountId: string) {
    return api.Get(`/modularised_advice/non_concessional_contributions/${accountId}`)
  },

  getLumpSumAnnualContribution(
    isConcessional: boolean,
    superFundClientAuthId?: string,
  ): Promise<
    AxiosResponse<{ id: number; amount: number; actioned: string; date: string }[] | null>
  > {
    return api.Post(`/modularised_advice/current-year-lump-sum-contribution`, {
      isConcessional: isConcessional,
      superFundClientAuthId,
    })
  },

  async setLumpSumAnnualContribution(payload: {
    amount: number
    concessional?: boolean
    esgSAB?: number
    esgYear?: number
    recommendedSab?: number
    recommendedYear?: number
    recommendationText?: string
    recommendationWhy?: string
    userCashflowImpact?: number
    originalRecommendationAmount?: number
    originalRecommendationCashflowImpact?: number
    recommendedBeforeTaxSuperContributions?: number
    maxMonthlyContribution?: number
  }): Promise<void> {
    return api.Post('/modularised_advice/lump-sum-contributions', payload)
  },

  deleteCurrentLumpSumAnnualContribution(
    isConcessional: boolean,
  ): Promise<AxiosResponse<void>> | undefined {
    return api.Delete(`/modularised_advice/current-year-lump-sum-contribution/${isConcessional}`)
  },
}
