<template>
  <div class="flex flex-col items-center h-full max-w-[570px]">
    <form @submit.prevent="next" class="flex flex-col items-center justify-center max-w-[375px]">
      <h6 class="font-bold self-start">Your {{ whitelabel.name }} member ID</h6>
      <BaseInput
        autofocus
        class="w-100% mt-[20px]"
        name="fund-member-id"
        :value="id"
        placeholder="Your member number"
        :error-message="errorText"
        @update:value="(val) => (id = val)" />
      <p class="paragraph-1 text-blue-1" :class="errorText ? 'mt-8' : 'mt-4'">
        Please add your member number so we can link the advice to your products.
      </p>
      <OtivoButton type="submit" class="w-100% mt-[60px]"> Next </OtivoButton>
    </form>
  </div>
</template>

<script setup lang="ts">
import OtivoButton from '@/components/OtivoButton.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { computed, ref, shallowRef } from 'vue'
import { useToast } from '@/composables/useToast.ts'
import mod10 from '@/composables/checksums/mod10.ts'
import { useAccountCreationStore } from '@/store/pinia/AccountCreationStore.ts'
import { useAuth0 } from '@/lib/AuthenticatorPlugin'
import { getValidator } from '@/composables/superfundValidators/validationHandler.ts'

const { errorToast } = useToast()
const sessionStore = useSessionStore()
const accountCreationStore = useAccountCreationStore()
const auth0 = useAuth0()
const auth0UserObject = computed(() => auth0.user.value)
const whitelabel = sessionStore.getWhitelabelData
const id = shallowRef<string>(accountCreationStore.uid ?? '')
const errorText = ref('')

const emit = defineEmits<{
  (e: 'next', data: string): void
}>()

const validate = async (val: string) => {
  const validator = await getValidator(whitelabel.identifier)
  return validator(val)
  // return mod10(val)
}

const next = async () => {
  try {
    // replace with 'superfund validation function'
    if (validate(id.value)) {
      accountCreationStore.setUid(id.value)
      await accountCreationStore.setUserMetadataMemberId(id.value, auth0UserObject.value.sub)
      emit('next', 'member id verified')
    } else {
      errorText.value = 'Please enter a valid member number.'
      errorToast('Please enter a valid member number.')
    }
  } catch (error) {
    errorToast(error?.message ?? 'Could not update your CFS member Id.')
  }
}
</script>

<style scoped></style>
