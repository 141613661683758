import SuperFundService from '@/services/SuperFundService.ts'

export const sendGuidanceOnboardEmail = async (auth0Id: string, forceSend = false) => {
  return SuperFundService.sendGuidanceOnboardEmail({
    auth0id: auth0Id,
    forceSend: forceSend,
  })
}

export const sendLegalWelcomeEmail = async (auth0Id: string) => {
  return SuperFundService.sendLegalWelcomeEmail({ auth0id: auth0Id })
}
