<template>
  <svg
    v-tooltip="{ content: info }"
    class="flex-none cursor-pointer"
    fill="none"
    height="16"
    viewBox="0 0 12 12"
    width="16"
    xmlns="http://www.w3.org/2000/svg">
    <path
      :class="`fill-${fill1}`"
      d="M5.99999 0C4.8133 0 3.65326 0.351894 2.66657 1.01118C1.67987 1.67047 0.910836 2.60754 0.45671 3.7039C0.00258443 4.80026 -0.116236 6.00665 0.115276 7.17054C0.346787 8.33443 0.918231 9.40352 1.75735 10.2426C2.59646 11.0818 3.66556 11.6532 4.82944 11.8847C5.99333 12.1162 7.19973 11.9974 8.29609 11.5433C9.39244 11.0891 10.3295 10.3201 10.9888 9.33342C11.6481 8.34672 12 7.18669 12 6C12 4.4087 11.3678 2.88258 10.2426 1.75736C9.11741 0.632141 7.59128 0 5.99999 0Z" />
    <path
      :class="`fill-${fill2}`"
      d="M6 4.3125C5.80109 4.3125 5.61032 4.39152 5.46967 4.53217C5.32902 4.67282 5.25 4.86359 5.25 5.0625V9.1875C5.25 9.38641 5.32902 9.57718 5.46967 9.71783C5.61032 9.85848 5.80109 9.9375 6 9.9375C6.19891 9.9375 6.38968 9.85848 6.53033 9.71783C6.67098 9.57718 6.75 9.38641 6.75 9.1875V5.0625C6.75 4.86359 6.67098 4.67282 6.53033 4.53217C6.38968 4.39152 6.19891 4.3125 6 4.3125Z" />
    <path
      :class="`fill-${fill2}`"
      d="M6 3.5625C6.41421 3.5625 6.75 3.22671 6.75 2.8125C6.75 2.39829 6.41421 2.0625 6 2.0625C5.58579 2.0625 5.25 2.39829 5.25 2.8125C5.25 3.22671 5.58579 3.5625 6 3.5625Z" />
  </svg>
</template>

<script lang="ts" setup>
type Props = {
  info?: string
  fill1?: string
  fill2?: string
}

withDefaults(defineProps<Props>(), {
  info: '',
  fill1: 'otivo-blue',
  fill2: 'white'
})
</script>
