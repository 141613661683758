import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { Resource } from '@opentelemetry/resources'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { ATTR_SERVICE_NAME, ATTR_SERVICE_VERSION } from '@opentelemetry/semantic-conventions'

// Define interface for configuration
interface OtelConfig {
  url: string
}

const traceExporter = (config: OtelConfig) =>
  new OTLPTraceExporter({
    url: config.url,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  })

const resource = new Resource({
  [ATTR_SERVICE_NAME]: 'otivo/frontend',
  [ATTR_SERVICE_VERSION]: '1.0.0',
  ['deployment.environment.name']: import.meta.env.MODE,
})

console.log('resource', resource)

// Update provider creation to accept config
export const createProvider = (config: OtelConfig) =>
  new WebTracerProvider({
    resource,
    spanProcessors: [new BatchSpanProcessor(traceExporter(config))],
  })

// Register automatic instrumentations
registerInstrumentations({
  instrumentations: [
    getWebAutoInstrumentations({
      '@opentelemetry/instrumentation-xml-http-request': {
        enabled: true,
        propagateTraceHeaderCorsUrls: [
          /http:\/\/localhost:.*/,
          /https:\/\/api\.otivo\.com:8080\/*/,
          /https:\/\/api-*\.otivo\.com:8080\/*/,
          /https:\/\/api\.otivo\.com.*/,
          /https:\/\/api-*\.otivo\.com\/*/,
          /https:\/\/api-*\.otivo\.dev\/*/,
        ],
      },
    }),
  ],
})

// Keep track of the current provider and tracer
let currentProvider: WebTracerProvider | null = null
let currentTracer: any | null = null

// Update initialization function to accept config and set the global instances
export function initializeOpenTelemetry(config: OtelConfig) {
  const provider = createProvider(config)

  // Set the context manager to use zone.js
  provider.register({
    contextManager: new ZoneContextManager(),
  })

  const tracer = provider.getTracer('frontend-tracer')

  // Set the current instances
  currentProvider = provider
  currentTracer = tracer

  console.log('OpenTelemetry initialized')
  return { provider, tracer }
}

// Export the current provider and tracer
export const provider = () => currentProvider
export const tracer = () => currentTracer
