import { RouteRecordRaw } from 'vue-router'
import PortalView from '@/views/Clients/CFS/PortalView.vue'
import { adminAuthGuard } from '@/router/clients/cfs/cfsRouteGuards.ts'
import CallcentrePortalAdminTable from '@/components/CallcentrePortal/Variants/CFS/CallcentrePortalAdminTable.vue'
import GuidanceClientSearchConfirm from '@/components/CFS/Guidance/GuidanceClientSearchConfirm.vue'
import CFSBackOfficeReports from '@/components/CFS/CFSBackOfficeReports.vue'
import CallcentrePortalMemberVerification from '@/components/CallcentrePortal/CallcentrePortalMemberVerification.vue'
import CallcentrePortalReporting from '@/components/CallcentrePortal/CallcentrePortalReporting.vue'
import CallcentrePortalOverview from '@/components/CallcentrePortal/CallcentrePortalOverview.vue'
import CallcentrePortalEngagement from '@/components/CallcentrePortal/CallcentrePortalEngagement.vue'
import { ISessionStore } from '@/store/pinia/SessionStore.ts'
import { IUserStore } from '@/store/pinia/UserStore.ts'
import MemberInfoForm from '@/components/CFS/Guidance/MemberInfoForm.vue'
import LoadingPage from '@/views/LoadingPage.vue'

export default (sessionStore: ISessionStore, userStore: IUserStore): Array<RouteRecordRaw> => {
  return [
    {
      path: '/admin',
      name: 'adminLogin',
      component: LoadingPage,
      beforeEnter: () => {
        sessionStore.login('Username-Password-Authentication')
      },
    },
    {
      path: '/admin-portal',
      redirect: '/admin-portal/search-users',
      component: () => PortalView,
      beforeEnter: (to, from, next) => adminAuthGuard(to, from, next, userStore, sessionStore),
      children: [
        // callcenter portal start
        {
          path: 'home',
          name: 'cfsHome',
          component: CallcentrePortalAdminTable,
        },
        {
          path: 'search-users',
          name: 'guidanceSearchUsers',
          component: GuidanceClientSearchConfirm,
        },
        {
          path: 'manage-user',
          name: 'manageUser',
          component: MemberInfoForm,
        },
        {
          path: 'verify-members',
          name: 'verifyMembers',
          component: CallcentrePortalMemberVerification,
        },
        {
          path: 'reports',
          name: 'cfsReports',
          component: CFSBackOfficeReports,
        },
        {
          path: 'reporting',
          name: 'portalReporting',
          redirect: '/admin-portal/reporting/overview',
          component: () => CallcentrePortalReporting,
          children: [
            {
              path: 'overview',
              name: 'portalReportingOverview',
              component: () => CallcentrePortalOverview,
              meta: {
                title: 'Reporting overview',
              },
            },
            {
              path: 'engagement',
              name: 'portalReportingEngagement',
              component: () => CallcentrePortalEngagement,
              meta: {
                title: 'Member engagement',
              },
            },
          ],
        },
      ],
    },
  ]
}
