<template>
  <!-- layout -->
  <div class="w-full flex flex-col min-h-100vh">
    <!-- header -->
    <header class="flex flex-col">
      <div
        class="w-full flex min-h-10 px-5 md:px-10 bg-midnight text-white align-middle items-center">
        <div class="grid grid-cols-3 gap-5 md:gap-10 items-center h-full">
          <AdminPortalLogo class="py-5 md:py-10 border-r border-otivo-blue md:pr-10" />
          <div
            v-if="whitelabel.render_whitelabel_logo"
            class="flex items-center h-full object-contain">
            <img
              class="max-w-[240px] h-auto"
              :src="whitelabel.logo"
              :alt="`${whitelabel.name}-logo`" />
          </div>
          <div
            v-else
            class="header flex text-blue-2 font-bold uppercase h-[85%] items-center py-4 my-auto">
            Admin Portal
          </div>
        </div>

        <nav class="flex grow items-center justify-center gap-10">
          <!-- <a class="text-blue-2 font-black text-xl" v-for="link in [1,2,3,4]" href="#link" :key="link">Link</a>-->
        </nav>
        <nav id="right-nav" class="flex flex-row gap-5">
          <EndCallButton />
          <a
            class="text-blue-2 self-center font-black text-xl underline underline-offset-[5px] hover:text-otivo-blue cursor-pointer"
            @click.prevent="handleSignOut"
            >Sign out</a
          >
        </nav>
      </div>
      <div class="w-full">
        <PortalUserBanner class="px-10 m-auto flex py-8 flex-row-reverse" v-if="user?.auth0id" />
      </div>
    </header>
    <div class="flex grow h-full bg-blue-6">
      <!-- sidebar -->
      <aside class="w-76 px-5 md:px-10 py-5 md:py-20 text-xl shrink-0">
        <RouterView name="sidebar" v-slot="{ Component }">
          <component :is="Component ? Component : AdminPortalSidenav" />
        </RouterView>
      </aside>
      <main class="h-full px-5 md:px-10 py-5 md:py-20 space-y-10 w-full">
        <!-- content -->
        <div class="w-full mx-auto">
          <RouterView />
        </div>
        <!-- ./content -->
      </main>
    </div>
    <!-- footer -->
    <footer
      class="w-full flex flex-col md:flex-row min-h-10 p-5 md:p-10 bg-midnight text-blue-2 text-center justify-center">
      <!--      <div-->
      <!--        v-if="whitelabel.logo"-->
      <!--        class="flex md:pl-10 flex items-center h-full object-contain self-center">-->
      <!--        <img class="max-w-[420px] h-auto" :src="whitelabel.logo" :alt="`${whitelabel.name}-logo`" />-->
      <!--      </div>-->
      <p class="flex self-center">{{ footer }}</p>
    </footer>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { useCallCentrePortalStore } from '@/store/pinia/adminPortal/CallCentrePortalStore.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useRouter } from 'vue-router'
import EndCallButton from '@/components/CFS/Guidance/EndCallButton.vue'
import ConfirmationModal from '@/components/Modals/ConfirmationModal.vue'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import PortalUserBanner from '@/components/CFS/Guidance/PortalUserBanner.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import AdminPortalSidenav from '@/features/admin-portal/components/AdminPortalSidenav.vue'
import AdminPortalLogo from '@/components/AdminPortal/AdminPortalLogo.vue'

const footer =
  'Otivo Pty Ltd ABN 47 602 457 732, AFSL + Australian Credit Licence 485665. Suite 2.01, 50 York Street, Sydney NSW 2000'

const router = useRouter()
const whitelabel = useSessionStore().getWhitelabelData
const user = computed(() => useUserStore().getUser)
const fetchingUser = ref(false)

onBeforeMount(async () => {
  if (!user.value.auth0id) {
    const managedUser = JSON.parse(localStorage.getItem('activeManagedUser'))
    if (managedUser) {
      fetchingUser.value = true
      await useCallCentrePortalStore().getManagedUserData(managedUser.auth0Id)
      fetchingUser.value = false
      router.push({ name: 'AccountStatus' })
    }
  }
})

// for header
const handleSignOut = () => {
  useModalStore().openModal(ConfirmationModal, {
    heading: 'Sign out',
    body: "Are you sure you're ready to logout?",
    confirmFunction: signOut,
  })
}

const signOut = () => {
  window.sessionStorage.clear()
  window.localStorage.clear()
  useSessionStore().logout(window.location.origin)
}
</script>
