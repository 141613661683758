<template>
  <div id="create-account-header" class="flex flex-col grow h-[100vh]">
    <div class="w-full relative bg-midnight pl-6 xl:px-20 min-h-32">
      <img
        v-if="!whitelabel.joint_logo"
        src="@/assets/img/otivo-logo-white-text.png"
        class="flex max-w-[130px] my-10"
        alt="otivo-logo" />
      <logo class="h-full" v-else />
    </div>

    <div class="flex flex-col bg-blue-6 flex-grow items-center">
      <div class="flex mt-15 md:mt-[120px]">
        <AccountCreationProgress :complete-percentage="progress" />
      </div>
      <div
        class="container flex flex-col px-5 md:px-10 w-100% h-100% py-5 sm:py-10 md:py-20 items-center">
        <RouterView @next="handleNext"></RouterView>
      </div>
    </div>

    <CustomFooter class="bg-midnight text-blue-4 relative w-full md:pt-4 z-10" />
  </div>
</template>

<script setup lang="ts">
import AccountCreationProgress from '@/components/CreateAccount/Partials/AccountCreationProgress.vue'
import { useRouter } from 'vue-router'
import { useToast } from '@/composables/useToast.ts'
import { computed } from 'vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import CustomFooter from '@/components/Footer/CustomFooter.vue'
import Logo from '@/components/Logo/Logo.vue'

const { successToast } = useToast()
const router = useRouter()
const sessionStore = useSessionStore()
const whitelabel = sessionStore.getWhitelabelData

// handling forward and back navigation for percentage bar
const progress = computed(() => {
  let value = 5
  switch (router.currentRoute.value.name) {
    case 'CaptureFundMemberId':
      return 30
    case 'CaptureSuperAccountNumber':
      return 45
    case 'CaptureSuperBalance':
      return 60
    case 'CaptureInvestmentOptions':
      return 75
    case 'SelectSuperFund':
      return 45
    case 'Paywall':
      return 95
    case 'TermsAndConditionsForm':
      return 85
    case 'SuperFundDisclaimer':
      return 85
  }
  return value
})

const handleNext = (val: string | number) => {
  switch (val) {
    case 201:
      if (whitelabel.superfund_config?.superfund_account_creation_enabled ?? false)
        router.push({ name: 'CaptureFundMemberId' })
      // ELSE GO TO NEXT REGULAR OTIVO ACCOUNT CREATION STEP
      else router.push({ name: 'SelectSuperFund' })
      break

    case 'member id verified':
      successToast('Member ID verified')
      router.push({ name: 'CaptureSuperAccountNumber' })
      break

    // SUPER FUND ACCOUNT CREATION STEPS
    case 'captured account number':
      router.push({ name: 'CaptureSuperBalance' })
      break

    case 'captured balance':
      router.push({ name: 'CaptureInvestmentOptions' })
      break

    case 'super details captured':
      successToast('Super details captured')

      if (whitelabel.superfund_config?.superfund_account_creation_enabled ?? false) {
        router.push({ name: 'SuperFundDisclaimer' })
        break
      }
      router.push({ name: 'TermsAndConditionsForm' })
      break
    // END

    case 'captured super fund':
      if (whitelabel.superfund_config?.superfund_account_creation_enabled ?? false) {
        router.push({ name: 'SuperFundDisclaimer' })
        break
      }
      router.push({ name: 'TermsAndConditionsForm' })
      break

    case 'terms and conditions accepted':
      if (whitelabel.superfund_config?.superfund_account_creation_enabled ?? false) {
        goToDashboard()
      } else {
        router.push({ name: 'Paywall' })
      }
      break

    case 'paid':
      goToDashboard()
      break

    default:
      throw new Error('Error handling next step in account creation process')
  }
}

const goToDashboard = () => {
  setTimeout(() => router.push({ name: 'clientDashboard' }), 1000)
}
</script>

<style scoped></style>
