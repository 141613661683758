<template>
  <div id="guidance-header" class="w-full relative bg-midnight">
    <div class="m-auto flex gap-2 items-center px-5 lg:px-10 relative z-90">
      <div class="flex flex-row h-full items-center gap-4 md:gap-10">
        <router-link to="/">
          <img
            src="@/assets/img/otivo-logo-white-text.png"
            class="self-center max-w-[130px] py-8"
            alt="otivo-logo" />
        </router-link>
        <div
          class="h-full hidden md:flex menu-1 text-blue-2 items-center pt-1 whitespace-nowrap"
          v-if="isAdmin()">
          GUIDANCE TEAM
        </div>
      </div>
      <div class="flex gap-6 justify-end items-center w-full" v-if="isAdmin()">
        <button
          v-if="userRole === 'superadmin'"
          class="button-2 text-blue-2 underline hidden lg:block"
          @click="router.push({ name: 'guidanceSearchUsers' })">
          Search for members
        </button>
        <button
          v-if="(userRole === 'backoffice' || userRole === 'superadmin') && enableDTD"
          class="button-2 text-blue-2 underline hidden lg:block"
          @click="router.push({ name: 'verifyMembers' })">
          Member Verification
        </button>
        <button
          v-if="
            userRole !== 'member' &&
            userRole !== 'client' &&
            userRole !== 'standard' &&
            !hideReporting
          "
          class="button-2 text-blue-2 underline hidden lg:block"
          @click="router.push({ name: 'portalReportingOverview' })">
          Reporting
        </button>
        <EndCallButton />
        <!--        &lt;!&ndash; Shouldn't have to worry about this disappearing on guidance team as they'll never be on mobile &ndash;&gt;-->
        <button
          class="button-2 text-blue-2 underline hidden lg:block"
          data-test="signOutBtn"
          @click="handleSignOut">
          Sign out
        </button>

        <div class="lg:hidden">
          <div :class="[{ open: toggleMenu }]" class="burger" @click="toggleMenu = !toggleMenu">
            <span class="line" />
            <span class="line" />
            <span class="line" />
          </div>
          <div
            :class="[{ open: toggleMenu }]"
            class="menu-draw otivo-drop-shadow transition-transform">
            <span> consent not bound</span>
            <SideMenuNavigation
              @click="toggleMenu = false"
              v-if="clientConsent && router.currentRoute.value.name !== 'clientSelectAccount'" />
            <button
              class="!w-100% mt-[40px] button-2 text-blue-2 underline"
              data-test="signOutBtn"
              @click="handleSignOut">
              Sign out
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SideMenuNavigation from '@/components/NavBar/SideMenuNavigation.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { computed, ref } from 'vue'
import { userRole } from '@/lib/AuthenticatorPlugin.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import ConfirmationModal from '@/components/Modals/ConfirmationModal.vue'
import EndCallButton from '@/components/CFS/Guidance/EndCallButton.vue'
import { useRouter } from 'vue-router'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import { useLDFlag, FeatureList } from '@/lib/ldClient.ts'

const router = useRouter()
interface Props {
  type?: string
}

const { isAdmin } = useCheckUser()

withDefaults(defineProps<Props>(), { type: 'dark' })

// TEMPORARY FLAG
const enableDTD = useLDFlag(FeatureList.ENABLE_DTD)
const hideReporting = useLDFlag(FeatureList.HIDE_REPORTING)
// END

const toggleMenu = ref(false)

// todo: refactor - add back consent
const clientConsent = computed(() => true)
// const guidanceClientConsent = computed(() => clientStore.getGuidanceClientConsent)

const modalStore = useModalStore()
const handleSignOut = () => {
  modalStore.openModal(ConfirmationModal, {
    heading: 'Sign out',
    body: "Are you sure you're ready to logout?",
    confirmFunction: signOut,
  })
}

const signOut = () => {
  window.sessionStorage.clear()
  window.localStorage.clear()
  let url = window.location.origin
  // TODO - this will no longer be the case for multi whitelabel clients
  // Handle this in the future
  url =
    userRole.value === 'callcentre' ||
    userRole.value === 'backoffice' ||
    userRole.value === 'superadmin'
      ? `${url}/admin`
      : `${url}/`
  useSessionStore().logout(url)
}
</script>

<style lang="scss" scoped>
.burger {
  width: 35px;
  height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .line {
    box-sizing: border-box;
    display: flex;
    border: 1px solid white;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
  }

  &.open {
    .line:first-child {
      transform: rotate(40deg) translate(6px, 6px);
    }

    .line:nth-child(2) {
      transition: none !important;
      opacity: 0;
    }

    .line:last-child {
      transform: rotate(-40deg) translate(5px, -5px);
    }
  }
}

.menu-draw {
  padding: 40px 20px;
  position: absolute;
  top: 100%;
  right: 0;
  background: rgba(255, 255, 255, 1);
  width: 100%;
  z-index: 90;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;

  &.open {
    top: 100%;
    transform: translateX(0);
    height: fit-content;
  }
}
</style>
