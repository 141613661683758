<template>
  <div class="flex justify-end items-center">
    <div class="gap-6 hidden md:flex" v-if="!isAuthenticated && !isCFSWhitelabel()">
      <OtivoButton
        class="w-[187px]"
        colour="red"
        size="medium"
        @click="auth0.createAccount"
        data-test="signUpBtn">
        {{ buttonText }}
      </OtivoButton>
      <OtivoButton
        class="w-[187px]"
        colour="transparent"
        size="medium"
        data-test="loginBtn"
        @click="auth0.login">
        Log in
      </OtivoButton>
    </div>
    <button
      v-else-if="isAuthenticated"
      class="min-w-20 button-2 text-blue-2 underline hidden lg:block"
      data-test="signOutBtn"
      @click="handleSignOut">
      Sign out
    </button>
    <!-- Burger Menu Only for Mobile -->
    <button
      v-if="!isAuthenticated"
      class="xl:hidden mx-[30px] cursor-pointer"
      @click="toggleSideBar"
      data-test="sideBarBtn">
      <svg
        width="30"
        height="18"
        viewBox="0 0 30 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <line y1="1" x2="30" y2="1" stroke="white" stroke-width="2" />
        <line y1="9" x2="30" y2="9" stroke="white" stroke-width="2" />
        <line y1="17" x2="30" y2="17" stroke="white" stroke-width="2" />
      </svg>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex'
import OtivoButton from '@/components/OtivoButton.vue'
import { isAuthenticated, useAuth0 } from '@/lib/AuthenticatorPlugin.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import ConfirmationModal from '@/components/Modals/ConfirmationModal.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import { isCFSWhitelabel } from '@/composables/isCFSWhitelabel.ts'
import { computed } from 'vue'

const store = useStore()
const auth0 = useAuth0()
const sessionStore = useSessionStore()
const { isAdmin } = useCheckUser()

const toggleSideBar = () => {
  store.dispatch('toggleSideBar')
}

const whitelabel = sessionStore.getWhitelabelData
const buttonText = computed(() => {
  return whitelabel.id === 1 ? 'Start free trial' : 'Get started'
})

const modalStore = useModalStore()
const handleSignOut = () => {
  modalStore.openModal(
    ConfirmationModal,
    {
      heading: 'Sign out',
      body: "Are you sure you're ready to logout?",
      confirmFunction: signOut,
    },
    'large',
    false,
    'background: white;',
  )
}

const signOut = () => {
  let url = window.location.origin
  url = isAdmin() ? `${url}/admin` : `${url}/`
  sessionStore.logout(url)
}
</script>

<style scoped></style>
