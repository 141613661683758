import SuperFundService from '@/services/SuperFundService.ts'
import {
  ClientUser,
  ShowDownloadPDFButtonOptionsType,
  TermDepositResponse,
} from '@/types/CallcentrePortalTypes.ts'
import { FieldValuePairType } from '@/types/GlobalTypes.ts'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useGuidanceClientErrorsStore } from '@/store/modules/GuidanceClientErrorsStore.ts'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore.ts'
import { useGuidanceSuperStore } from '@/store/pinia/GuidanceSuperStore.ts'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'
import { useRouter } from 'vue-router'
import { managedClientTemplate } from '@/lib/IntrafundGuidanceHelper.ts'
import { FlagKey } from '@/store/pinia/AccountFlagsStore.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'

export const useGuidanceClientStore = defineStore('GuidanceClientStore', () => {
  const router = useRouter()
  const intrafundAdviceStore = useIntrafundAdviceStore()
  const guidanceSuperStore = useGuidanceSuperStore()
  const clientErrorsStore = useGuidanceClientErrorsStore()
  const moduleStatusStore = useModuleStatusesStore()

  const guidanceClient = ref<ClientUser>(managedClientTemplate())
  const guidanceClientConsent = ref<boolean>(false)
  const guidanceClientConsentEmailSent = ref<boolean>(false)
  const guidanceClientsSearched = ref<boolean>(false)
  const readClientLoading = ref<boolean>(false)
  const showDownloadPDFButton = ref<ShowDownloadPDFButtonOptionsType>('complete') // complete is now the 'download' state
  const currentAdviceName = ref<FlagKey>()

  const getModuleStatuses = computed(() => moduleStatusStore.getStatuses)
  const getCurrentAdviceTargetName = computed(() => currentAdviceName.value)
  const clientLoggedIn = computed(() => guidanceClient.value.auth0Id !== '')
  const getGuidanceClientConsent = computed(() => guidanceClientConsent.value)
  const getGuidanceClientConsentEmailSent = computed(() => guidanceClientConsentEmailSent.value)
  const getGuidanceClientData = computed(() => guidanceClient.value)
  const getGuidanceClientsSearched = computed(() => guidanceClientsSearched.value)
  const getReadClientLoading = computed(() => readClientLoading.value)
  const getShowDownloadPDFButton = computed(() => showDownloadPDFButton.value)

  interface ConsentResponse {
    account_id: number
    expires_at: string
    consented: boolean
    fee_consent_generated_flag: number
    fee_consent_generated_at: string
    fee_consent_url: string
    advice_received: number
    cash_investment_acknowledged: number
    consentEmailSent: boolean
  }

  const confirmCFSMemberDetails = (payload: { email: string; member_id: string }) => {
    return SuperFundService.confirmMemberDetails(payload)
  }

  const setCFSClient = (payload: ClientUser) => {
    // Set full client object
    return (guidanceClient.value = payload)
  }
  const updateGuidanceClientField = (payload: FieldValuePairType<unknown>) => {
    // Generic[T] update for guidanceClient
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    guidanceClient.value[payload.field] = payload.value
  }

  const setGuidanceClientConsent = (payload: {
    consented: boolean
    expiresAt: number
    account: {
      account_flags: {
        guidance_client_consent_email_sent: boolean
      }
    }
  }): void => {
    guidanceClientConsent.value = payload.consented
    guidanceClientConsentEmailSent.value =
      payload.account.account_flags.guidance_client_consent_email_sent
  }

  /**
   * UI RELATED
   */
  const clientRouteRedirect = (consentResponse: boolean) => {
    console.log(consentResponse)

    const routeName = router.currentRoute.value.name
    if (routeName === 'clientDisclaimer' && consentResponse) {
      router
        .replace({ name: 'superInvestments' })
        .catch((err) => console.warn('error redirecting to superWidget', err))
    }
  }
  const readClientData = async (auth0Id: string, allowRedirect = true): Promise<void> => {
    if (readClientLoading.value) return

    const guidanceSuperStore = useGuidanceSuperStore()
    readClientLoading.value = true

    try {
      const { data } = await SuperFundService.readClientData({ auth0Id })
      const clientData: ClientUser = data

      const payload: ClientUser = {
        ...clientData,
        auth0Id,
      }

      const superIndex = guidanceSuperStore.getSuperIndex ?? 0
      const supersArray = guidanceSuperStore.getSupersArray

      guidanceSuperStore.setSupersArray(supersArray)
      setCFSClient(payload)

      if (
        supersArray.length > 0 &&
        supersArray[superIndex]?.id &&
        guidanceSuperStore.getClientSuperObject.id === ''
      ) {
        await guidanceSuperStore.getClientSuper(supersArray[superIndex].id)
      }

      guidanceSuperStore.addEmptySuperFund()

      const consentResult = await fetchGuidanceClientConsent()
      if (allowRedirect) clientRouteRedirect(consentResult)
    } catch (error) {
      console.error('Error reading client data:', error)
    } finally {
      readClientLoading.value = false
    }
  }

  /**
   * FLAG RELATED
   */
  const fetchGuidanceClientConsent = async (): Promise<boolean> => {
    const intrafundAdviceStore = useIntrafundAdviceStore()
    const userStore = useUserStore()

    try {
      await intrafundAdviceStore.fetchTermDepositConsent()

      const accountId = guidanceClient.value?.accountId ?? userStore.getUser?.account_id

      if (!accountId) {
        throw new Error('Account ID is not set')
      }

      // Fetch the consent flag
      const response = await SuperFundService.getConsentFlag(accountId)
      const consentData = response.data as ConsentResponse

      const currentTimestamp = Date.now()
      const expirationTimestamp = parseInt(consentData.expires_at)

      const isConsentValid = expirationTimestamp > currentTimestamp && consentData.consented

      guidanceClientConsent.value = isConsentValid
      guidanceClientConsentEmailSent.value = consentData.consentEmailSent

      return isConsentValid
    } catch (error) {
      console.error('Error fetching guidance client consent:', error)
      return false
    }
  }
  /**
   * AUTH/LOGIN RELATED
   */
  const resendCFSClientSMS = (payload: { email: string; memberId: string }) => {
    return SuperFundService.resendClientSMS(payload)
  }

  const resetCFSClient = () => {
    guidanceClient.value = managedClientTemplate()
    guidanceClientConsentEmailSent.value = false
    intrafundAdviceStore.updateTermDepositConsent({} as TermDepositResponse)
    guidanceClientConsent.value = false
  }

  const logoutClientAccount = () => {
    // resetClient()
    guidanceSuperStore.resetClientSuper()
    // resetCFSSearchMemberResults()
    clientErrorsStore.resetSuperWidgetErrors()
  }

  /**
   * FLAGS
   */
  const setGuidanceClientConsentFlag = () => {
    const date = new Date().getTime()
    return SuperFundService.setConsentFlag({
      consented: true,
      cash_investment_acknowledged: intrafundAdviceStore.getHasCashAndTermDepositInvestments,
      expires_at: date + 31536000000, // + 1 year
      account_id: guidanceClient.value.accountId,
    })
  }

  /**
   * FLAGS
   */
  const setAdviceReceivedFlag = () => {
    return SuperFundService.setConsentFlag({
      advice_received: true,
      account_id: guidanceClient.value.accountId,
    })
  }

  /**
   * UI RELATED
   */
  const setShowDownloadPDFButton = (value: ShowDownloadPDFButtonOptionsType) => {
    showDownloadPDFButton.value = value
  }

  const setAdviceRouterTargetName = (routeName: string) => {
    currentAdviceName.value = routeName as FlagKey
  }

  return {
    clientLoggedIn,
    getGuidanceClientConsent,
    getGuidanceClientConsentEmailSent,
    getGuidanceClientData,
    getGuidanceClientsSearched,
    getReadClientLoading,
    getShowDownloadPDFButton,
    getCurrentAdviceTargetName,
    getModuleStatuses,
    setAdviceRouterTargetName,
    confirmCFSMemberDetails,
    fetchGuidanceClientConsent,
    logoutClientAccount,
    readClientData,
    resendCFSClientSMS,
    resetCFSClient,
    setAdviceReceivedFlag,
    setCFSClient,
    setGuidanceClientConsent,
    setGuidanceClientConsentFlag,
    setShowDownloadPDFButton,
    updateGuidanceClientField,
  }
})
