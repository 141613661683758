<template>
  <BaseView>
    <div
      class="md:w-[800px] mx-auto my-10 bg-white pb-[50px] px-[20px] md:px-[40px] rounded-[20px] border-[1px] border-blue-4 otivo-shadow">
      <h5 class="pt-[40px] font-bold text-blue-1">Frequently asked questions</h5>
      <p class="intro-1 text-blue-1 mt-[20px] mb-[40px]">
        <!-- Introduction for the FAQ section -->
        CFS and Otivo working together to deliver financial advice to members
      </p>
      <div class="m-auto rounded mt-[30px] justify-center">
        <div v-for="(faqs, index) in filteredFaqs" :key="index" class="otivo-shadow">
          <h6
            v-if="positionHeader(index) !== ''"
            class="m-auto bg-blue-6 text-left text-blue-1 pt-[20px] pb-[20px] px-[20px] md:px-[40px] mt-[40px] rounded-t-[10px] border-[1px] border-blue-4">
            {{ positionHeader(index) }}
          </h6>
          <accordion
            :id="faqContent.slug + '_accordion'"
            v-for="faqContent in faqs"
            :anchor="faqContent.slug"
            :class="[
              { 'border-blue-5': index !== filteredFaqs.length - 1 },
              'border-[1px] border-blue-4 ',
            ]"
            :title="faqContent.heading"
            :content="faqContent.body"
            :styles="{
              title: 'text-[18px] font-bold',
              content: 'px-[20px] md:px-[40px] pt-[10px] pb-[20px] max-w-screen list-disc',
              base: 'px-[20px] md:px-[40px] py-[20px] border-none max-w-screen',
            }" />
        </div>
      </div>
    </div>
  </BaseView>
</template>

<script setup lang="ts">
import Accordion from '@/components/Accordion.vue'
import { computed, onBeforeMount } from 'vue'
import { useFAQStore } from '@/store/pinia/FAQs'
import { isAuthenticated } from '@/lib/AuthenticatorPlugin'
import BaseView from '@/views/BaseView.vue'

const faqStore = useFAQStore()
onBeforeMount(() => {
  faqStore.fetchFAQs('faq')
})

// Function to group data items by their position or set it to 1 if undefined
const groupByOrderAndSetDefault = (data: any[]): { [key: number]: any[] } => {
  // Initialize an empty object to store the grouped data
  const result: { [key: number]: any[] } = {}

  // Loop through each data item
  data.forEach((item) => {
    // Extract the 'position' property from the data item or default to 1
    const position = item.position || 1

    // If the result object does not have a key for this position, create it as an empty array
    if (!result[position]) {
      result[position] = []
    }

    // Push the data item into the corresponding position in the result object
    result[position].push(item)
  })

  // Return the result object containing grouped data
  return result
}

const filteredFaqs = computed(() => {
  if (isAuthenticated.value) {
    // User is logged in, display all FAQs
    return groupByOrderAndSetDefault(faqStore.faqs)
  } else {
    // User is not logged in, filter FAQs with position === 1
    return {
      1: faqStore.faqs.filter((faq) => faq.position === 1),
    }
  }
})

// set the header at the top of each section based on position number
const positionHeader = (position: number) => {
  return (
    {
      1: 'How the advice works',
      2: 'Investment options inside super',
      3: 'Contributions from your salary',
      4: 'Tax deductible contributions from savings',
      5: 'Non-deductible contributions from savings',
      6: 'General information about using the contribution module',
      7: 'Retirement income',
      8: 'Personal insurance',
    }[position] ?? ''
  )
}
</script>
