import SuperFundService from '@/services/SuperFundService.ts'
import { AxiosError } from 'axios'

/**
 * CFS REPORTING: propose moving this to something under /Client/CFS or something
 */
export const fetchCFSBackendReports = async () => {
  const reportsResponse = await SuperFundService.fetchBackendReports().catch((err: AxiosError) => {
    console.warn(err)
  })
  return reportsResponse.data.data
}
export const downloadCFSBackendReport = (link: string) => {
  return SuperFundService.downloadSuperFundBackendReport(link)
}
