import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
  AxiosHeaders,
} from 'axios'
import { ref } from 'vue'
import * as Sentry from '@sentry/core'
import {
  trace,
  context,
  defaultTextMapSetter,
  SpanStatusCode,
  SpanKind,
  Span,
} from '@opentelemetry/api'
import { W3CTraceContextPropagator } from '@opentelemetry/core'
import { tracer } from '@/lib/otel.ts'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: '',
  'Otivo-Impersonation': '',
  'Otivo-Client-Key': '',
  'sentry-trace': '',
  baggage: '',
  traceparent: '',
  tracestate: '',
}

interface OtelAxiosRequestConfig extends InternalAxiosRequestConfig {
  otelSpan?: Span
}

type Response<T> = Promise<AxiosResponse<T>>
const instance = ref<AxiosInstance>()
const authenticated = ref(false)
const tokenCache = ref<string | null>()

let abortController: AbortController | null = null

const getAPIUrl = () => {
  if (import.meta.env.MODE === 'development') {
    if (import.meta.env.VITE_TEST_BASE_API_URL) {
      return import.meta.env.VITE_TEST_BASE_API_URL
    }

    if (location.hostname !== '127.0.0.1') {
      return `https://api-${location.hostname}/api`
    }

    return 'https://laravel.devel/api'
  } else return `https://api-${location.hostname}/api`
}

const createAbortController = () => {
  abortController = new AbortController()
  return abortController
}

const abortPendingRequest = async () => {
  if (abortController) {
    abortController.abort()
    createAbortController() // Create a new AbortController for future requests
  }
  return true
}

const api = (() => {
  const createAxiosInstance = (): AxiosInstance => {
    const instance = axios.create({
      baseURL: getAPIUrl(),
      headers: headers,
      timeout: 60_000,
      timeoutErrorMessage: 'Request timed out',
    })

    instance.interceptors.request.use((requestConfig: OtelAxiosRequestConfig) => {
      if (abortController) {
        requestConfig.signal = abortController.signal
      }

      // Generate a more descriptive span name
      const url = new URL(requestConfig.url || '', requestConfig.baseURL)
      const spanName = `HTTP ${requestConfig.method?.toUpperCase()} ${url.pathname}`

      // Start a span with more detailed attributes
      const span = tracer()?.startSpan(spanName, {
        kind: SpanKind.CLIENT,
        attributes: {
          'http.method': requestConfig.method?.toUpperCase(),
          'http.url': url.toString(),
          'http.host': url.host,
          'http.path': url.pathname,
          'http.scheme': url.protocol.replace(':', ''),
          'http.target': url.pathname + url.search,
        },
      })

      // Store span in request config for later use in response handler
      if (span) {
        requestConfig.otelSpan = span

        // Create context with our span
        const ctx = trace.setSpan(context.active(), span)

        // Inject W3C TraceContext headers
        const carrier: { [key: string]: string } = {}
        const propagator = new W3CTraceContextPropagator()
        propagator.inject(ctx, carrier, defaultTextMapSetter)

        // Set headers properly using Axios config
        requestConfig.headers = AxiosHeaders.concat(requestConfig.headers, {
          traceparent: carrier.traceparent,
          tracestate: carrier.tracestate,
        })
      }

      return requestConfig
    })

    instance.interceptors.response.use(
      (response: AxiosResponse<any, any> & { config: OtelAxiosRequestConfig }) => {
        // End the span when the response is received
        const span = response.config.otelSpan
        if (span) {
          span.setAttributes({
            'http.status_code': response.status,
            'http.status_text': response.statusText,
            'http.response_content_length': response.headers['content-length'],
          })

          // Set span status based on HTTP status code
          if (response.status < 400) {
            span.setStatus({ code: SpanStatusCode.OK })
          } else {
            span.setStatus({
              code: SpanStatusCode.ERROR,
              message: `HTTP Error ${response.status}: ${response.statusText}`,
            })
          }

          span.end()
        }
        return response
      },
      (error) => {
        // End the span when an error occurs
        const span = error.config?.otelSpan
        if (span) {
          const errorAttrs: Record<string, any> = {
            'error.type': error.name,
            'error.message': error.message,
          }

          if (error.response) {
            errorAttrs['http.status_code'] = error.response.status
            errorAttrs['http.status_text'] = error.response.statusText
          } else if (error.request) {
            // The request was made but no response was received
            errorAttrs['error.type'] = 'RequestError'
          } else {
            // Something happened in setting up the request
            errorAttrs['error.type'] = 'SetupError'
          }

          if (error.code) {
            errorAttrs['error.code'] = error.code
          }

          span.setAttributes(errorAttrs)
          span.setStatus({
            code: SpanStatusCode.ERROR,
            message: error.message,
          })

          span.end()
        }
        return Promise.reject(error)
      },
    )

    return instance
  }

  const setAuthenticated = async (status: boolean, token: string) => {
    authenticated.value = status
    if (status) {
      try {
        if (!token) {
          token = localStorage.getItem('at') || ''
        }
      } catch (err) {
        console.error(err)
      }

      tokenCache.value = token
      headers.Authorization = `Bearer ${token}`
      if (!instance.value) {
        instance.value = createAxiosInstance()
      }
      // update instance headers
      instance.value.defaults.headers['Authorization'] = `Bearer ${token}`
    }
  }

  const setSentryTraceHeader = () => {
    const activeSpan = Sentry.getActiveSpan()
    const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined

    // Create `sentry-trace` header
    const trace = rootSpan ? Sentry.spanToTraceHeader(rootSpan) : undefined
    const sentryBaggageHeader = rootSpan ? Sentry.spanToBaggageHeader(rootSpan) : undefined

    try {
      if (!instance.value) return
      instance.value.defaults.headers['sentry-trace'] = trace || ''
      instance.value.defaults.headers['baggage'] = sentryBaggageHeader || ''
    } catch (e) {
      console.error(e)
    }
  }

  const setClientHeader = (id: string) => {
    if (id && instance.value) {
      instance.value.defaults.headers['Otivo-Client-Key'] = btoa(id)
      return
    }
    console.warn('no id')
  }

  const setImpersonationHeader = (token?: string) => {
    const tk = token ? token : sessionStorage.getItem('impersonationToken')
    if (tk && instance.value) {
      instance.value.defaults.headers['Otivo-Impersonation'] = tk
      return true
    }
    return false
  }

  const handleError = (error: any) => {
    if (error.code === 'ERR_CANCELED') {
      console.warn('Request canceled:', error)
    } else if (error.response) {
      // Handle HTTP error responses
      throw error
    } else if (error.request) {
      // Handle network errors (no response received)
      console.error('Network error - no response received:', error)
      throw error
    } else {
      // Handle request setup errors
      console.error('Request setup error:', error)
      throw error
    }
  }

  const Get = <T>(url: string, config?: AxiosRequestConfig<object>): Response<T> => {
    if (!instance.value) throw new Error('No Axios instance')
    try {
      return instance.value.get<T>(url, {
        ...config,
        signal: abortController?.signal || undefined,
      })
    } catch (e) {
      handleError(e)
      throw e
    }
  }

  const Post = <T>(
    url: string,
    body: object = {},
    config?: AxiosRequestConfig<object>,
  ): Response<T> => {
    if (!instance.value) throw new Error('No Axios instance')
    try {
      return instance.value.post(url, body, {
        ...config,
        signal: abortController?.signal || undefined,
      })
    } catch (e) {
      handleError(e)
      throw e
    }
  }

  const Put = <T>(url: string, body?: object, config?: AxiosRequestConfig<object>): Response<T> => {
    if (!instance.value) throw new Error('No Axios instance')
    try {
      return instance.value.put(url, body, {
        ...config,
        signal: abortController?.signal || undefined,
      })
    } catch (e) {
      handleError(e)
      throw e
    }
  }

  const Patch = <T>(
    url: string,
    body?: object,
    config?: AxiosRequestConfig<object>,
  ): Response<T> => {
    if (!instance.value) throw new Error('No Axios instance')
    try {
      return instance.value.patch(url, body, {
        ...config,
        signal: abortController?.signal || undefined,
      })
    } catch (e) {
      handleError(e)
      throw e
    }
  }

  const Delete = <T>(url: string, config?: object): Response<T> => {
    if (!instance.value) throw new Error('No Axios instance')
    try {
      return instance.value.delete(url, {
        ...config,
        signal: abortController?.signal || undefined,
      })
    } catch (e) {
      handleError(e)
      throw e
    }
  }

  const getRequestType = (type: 'post' | 'get' | 'put' | 'patch' | 'delete') => {
    switch (type) {
      case 'post':
        return Post
      case 'get':
        return Get
      case 'put':
        return Put
      case 'patch':
        return Patch
      case 'delete':
        return Delete
    }
  }

  createAbortController()
  if (!instance.value) instance.value = createAxiosInstance()

  return {
    Get,
    Post,
    Put,
    Patch,
    Delete,
    getRequestType,
    setAuthenticated,
    abortPendingRequest,
    setSentryTraceHeader,
    setClientHeader,
    setImpersonationHeader,
    authenticated,
    tokenCache,
    instance: instance.value,
  }
})()

const getApiInstance = () => api
export default getApiInstance
