<template>
  <div class="w-full max-w-[1240px] pt-5">
    <form
      class="bg-white rounded p-[40px] pt-[30px] border border-blue-4.5 mt-5"
      @submit.prevent="submit">
      <div class="flex justify-between">
        <h6 class="self-center text-blue-1 flex gap-3 items-center">
          <SuperSun fill2="otivo-red" v-if="!confirmationPage" /> Personal info
        </h6>
        <div class="flex flex-col">
          <div
            v-if="showEditButton && clientLoggedIn"
            @click="editable = !editable"
            class="underline text-otivo-blue button-2 mt-[10px] cursor-pointer hover:text-blue-1 text-right">
            {{ editable ? 'Lock Account' : 'Edit Account' }}
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 w-full mt-[40px]">
        <div class="flex flex-col col-span-1 gap-2 mt-2" v-if="isCallcentre">
          <label for="CFS_details_id" class="w-fit button-2">Member ID</label>
          <BaseInput
            type="text"
            :disabled="!canEdit || !editable"
            :error-message="formErrors.member_id"
            :value="form.member_id"
            class="w-full cfs-guidance-member-input"
            name="CFS_details_id"
            placeholder="06112345"
            @update:value="(val: string) => (form.member_id = val)" />
        </div>

        <div class="col-span-1 flex flex-col gap-2 mt-2" v-if="isCallcentre">
          <label for="cfs_account_number" class="w-fit button-2">
            <span class="flex flex-row gap-1">
              Account number
              <InfoCircle
                class="flex"
                message="Your CFS Super account number which starts with 011 or 051 or 065" />
            </span>
          </label>
          <BaseInput
            type="text"
            :disabled="!canEdit || !editable"
            :error-message="formErrors.account_number"
            :value="form.account_number"
            class="w-full cfs-guidance-member-input"
            name="cfs_account_number"
            placeholder="Primary super account number"
            @update:value="(val: string) => validateAccountNumber(val)" />
        </div>
        <div class="col-span-1 flex justify-end align-bottom" v-if="isCallcentre"></div>
        <div class="flex flex-col col-span-1 gap-2">
          <label for="CFS_details_first_name" class="w-fit button-2"> First name </label>
          <BaseInput
            type="text"
            validation="alpha"
            :max-length="50"
            :disabled="!canEdit || !editable"
            :error-message="formErrors.first_name"
            :value="form.first_name"
            class="w-full cfs-guidance-member-input"
            name="CFS_details_first_name"
            placeholder="First name"
            @update:value="(val: string) => (form.first_name = capitalize(val))" />
        </div>

        <div class="flex flex-col col-span-1 gap-2">
          <label for="CFS_details_middle_name" class="w-fit button-2">
            <span class="flex gap-1">
              Middle name
              <InfoCircle
                class="flex"
                message="Leave a space between extra middle names eg. Sage Remy" />
            </span>
          </label>
          <BaseInput
            type="text"
            validation="alpha"
            :disabled="!canEdit || !editable"
            :value="form.middle_name"
            class="w-full cfs-guidance-member-input"
            name="CFS_details_middle_name"
            :placeholder="userRole === 'member' ? '' : 'Middle name'"
            @update:value="(val: string) => (form.middle_name = capitalize(val))" />
        </div>

        <div class="flex flex-col col-span-1 gap-2">
          <label for="CFS_details_last_name" class="w-fit button-2"> Last name </label>
          <BaseInput
            :disabled="!canEdit || !editable"
            :error-message="formErrors.last_name"
            :max-length="50"
            :placeholder="'Last name'"
            :value="form.last_name"
            class="w-full cfs-guidance-member-input"
            name="CFS_details_last_name"
            type="text"
            @update:value="(val: string) => (form.last_name = capitalize(val))" />
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <label for="CFS_details_email" class="w-fit button-2"> Email </label>
          <BaseInput
            :disabled="!canEdit || !editable"
            :error-message="formErrors.email"
            :value="form.email"
            class="w-full cfs-guidance-member-input"
            name="CFS_details_email"
            placeholder="email@address.com"
            type="email"
            @update:value="(val: string) => (form.email = val)" />
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <label for="CFS_details_date_of_birth" class="w-fit button-2"> Date of birth </label>
          <BaseDateInput
            :disabled="!canEdit || !editable"
            :error-message="formErrors.dob"
            :max="getMaxDateForDobString()"
            :min="getMinDateForDobString()"
            :value="form.dob"
            class="w-full cfs-guidance-member-input"
            name="CFS_details_date_of_birth"
            @update:value="(val: string) => (form.dob = val)" />
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <label for="cfs_details_mobile_number" class="w-fit button-2">
            <span class="flex gap-1">
              Mobile number
              <InfoCircle class="flex" message="International or mobile code (+61 or 04)" />
            </span>
          </label>
          <BaseInput
            :disabled="!canEdit || !editable"
            :error-message="formErrors.mobile"
            :value="form.mobile"
            class="w-full cfs-guidance-member-input"
            name="cfs_details_mobile_number"
            placeholder="0412 345 678"
            type="phone"
            @update:value="(val: string) => (form.mobile = val)" />
        </div>
      </div>
      <div v-if="userRole !== 'member'" class="flex w-full gap-4 md:gap-8 mt-[60px]">
        <!-- MT -> Split it into 3 buttons as it was getting confusing-->
        <div v-if="clientLoggedIn" class="flex gap-4 w-1/2">
          <OtivoButton
            v-if="isAdmin()"
            type="button"
            size="large"
            colour="blue"
            data-test="nextBtn"
            class="w-1/2 border"
            @click="goToAdvice"
            :disabled="loading || forceDisabled">
            Next
          </OtivoButton>
          <OtivoButton
            type="submit"
            :colour="'white'"
            size="large"
            class="w-1/2 border"
            data-test="saveBtn"
            :loading="loading"
            :disabled="loading || !editable || forceDisabled">
            Save
          </OtivoButton>
        </div>
        <OtivoButton
          v-else
          size="large"
          type="submit"
          colour="blue"
          class="border w-1/4"
          :disabled="forceDisabled"
          data-test="createAccountBtn"
          :loading="loading">
          Create
        </OtivoButton>
      </div>
      <div v-if="isAdmin() && !consent && clientLoggedIn" class="w-full flex justify-end mt-[20px]">
        <div
          @click="toggleDeleteMemberAccountModal"
          class="underline text-otivo-blue button-2 cursor-pointer hover:text-blue-1 inline-flex">
          Delete member account
        </div>
      </div>
      <p
        v-if="userRole === 'member' && !confirmationPage"
        class="paragraph-2 text-grey-2 mt-[20px] md:max-w-[895px]">
        Otivo’s advice relies on the personal information you’ve given us. If any of this
        information is incomplete or incorrect, you risk receiving advice which may not be
        appropriate for you. If you need to update any personal information, please call
        {{ whitelabel.name }} on {{ whitelabel.phone_number ?? 'NO PHONE_NUMBER ON WHITELABEL' }}.
      </p>
    </form>
    <ConfirmAlertBox
      v-if="deleteModalOpen"
      msg="Are you sure you want to delete this members account?"
      heading="Delete member account"
      @close="deleteMemberAccount" />
  </div>
</template>
<script setup lang="ts">
import { capitalize, computed, onBeforeMount, reactive, ref, watchEffect } from 'vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import SuperFundService from '@/services/SuperFundService.ts'
import { commonValidators, useFormValidator } from '@/composables/useFormValidator.ts'
import { useCallCentrePortalStore } from '@/store/pinia/adminPortal/CallCentrePortalStore.ts'
import { useGuidanceClientStore } from '@/store/pinia/GuidanceClientStore.ts'
import { useRouter } from 'vue-router'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { useToast } from '@/composables/useToast.ts'
import ConfirmAlertBox from '@/components/Dialogs/ConfirmAlertBox.vue'
import SuperSun from '@/components/SVGS/SuperSun.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
import BaseDateInput from '@/components/Inputs/BaseDateInput.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import ResetAdviceModal from '@/components/Modals/ResetAdviceModal.vue'
import { getMaxDateForDobString, getMinDateForDobString } from '@/composables/getDateRangeForDob.ts'
import { userRole } from '@/lib/AuthenticatorPlugin.ts'

const sessionStore = useSessionStore()
const userStore = useUserStore()
const callCentrePortalStore = useCallCentrePortalStore()
const clientStore = useGuidanceClientStore()
const router = useRouter()
const superStore = useSuperStore()
const modalStore = useModalStore()
const moduleStatus = useModuleStatusesStore()

const whitelabel = sessionStore.getWhitelabelData

const { isAdmin } = useCheckUser()
const { successToast, errorToast } = useToast()

const clientLoggedIn = computed(() => user.value?.auth0id ?? false)

const user = computed(() => userStore.getUser)
const consent = computed(() => user.value?.has_consented)
const canEdit = computed(() => isAdmin() || user.value.dtd_member)
const isCallcentre = computed(() => sessionStore.getWhitelabelData.callcentre_portal)
const editable = ref(!user.value?.auth0id)
const showEditButton = computed(() => canEdit.value && clientLoggedIn)
const activeSuperObject = computed(() => superStore.getActiveSuperObject)

const loading = ref<boolean>(false)
const deleteModalOpen = ref<boolean>(false)

const form = reactive({
  first_name: user.value?.first_name,
  middle_name: user.value?.middle_name,
  last_name: user.value?.last_name,
  email: user.value?.email,
  dob: user.value?.dob,
  mobile: user.value?.mobile,
  member_id: user.value?.member_id,
  account_number: activeSuperObject.value.account_number ?? user.value?.account_number ?? '',
})

type Props = {
  confirmationPage?: boolean
}

withDefaults(defineProps<Props>(), {
  confirmationPage: false,
})

const goToAdvice = () => {
  router.push({ name: 'superInvestments' })
}

const submit = () => {
  // if the user has actioned any advice
  if (!moduleStatus.hasActionedAnyAdvice()) {
    submitForm()
    return
  }

  modalStore.openModal(ResetAdviceModal, {
    onClose: modalStore.closeModal,
    onProceed: () => {
      modalStore.closeModal()
      submitForm()
    },
  })
}

/**
 * POST if creating new user
 * PUT if updating existing user
 * adds auth0 id to request if it exists
 */
const submitForm = async () => {
  const { isValid } = validateForm(form)
  if (!isValid) return
  loading.value = true
  if (user.value?.auth0id) {
    try {
      if (form.account_number !== user.value.account_number) {
        await superStore.setupSuper(form.account_number, activeSuperObject.value.id)
      }
      const res = await SuperFundService.updateSuperFundClient(form)
      userStore.setUser(res.data)
      successToast('User updated successfully')
    } catch (e) {
      console.error(e)
      errorToast('Error updating user')
    } finally {
      loading.value = false
    }

    return
  }

  // User is required to be admin to create a user (should never need to check this but safety first!)
  if (isAdmin()) {
    try {
      await callCentrePortalStore.createManagedUser(form)
    } catch (e) {
      console.error(e)
      if (e.status === 409) formErrors[e.data.type] = e.data.error
      errorToast('Error creating user')
    } finally {
      loading.value = false
    }
  }
}

const forceDisabled = ref(false)
const validateAccountNumber = (val: string) => {
  formErrors.account_number = ''
  if (sessionStore.getWhitelabelData.superfund_config?.super_products?.length) {
    if (val.length !== 12) formErrors.account_number = 'Invalid account number'

    const validAccountNumbers = sessionStore.getWhitelabelData.superfund_config.super_products.map(
      (product) => product.code,
    )

    /**
     * NOTE: this only really applies to CFS because their identifier is the first 3 digits.
     * Re-evaluate when there's another one like this
     */
    const first3Digits = val.slice(0, 3)
    if (!validAccountNumbers.includes(first3Digits)) {
      formErrors.account_number = 'Invalid account number'
      return
    }

    forceDisabled.value = formErrors.account_number !== ''
  }
  form.account_number = val
}

const { formErrors, validateForm } = useFormValidator(
  {
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    dob: '',
    mobile: '',
    member_id: '',
    account_number: '',
    desired_retirement_age: '',
    desired_retirement_income: '',
  },
  {
    first_name: {
      required: true,
      validate: commonValidators.required,
      message: 'First name is required',
    },
    last_name: {
      required: true,
      validate: commonValidators.required,
      message: 'Last name is required',
    },
    email: {
      required: true,
      validate: commonValidators.email,
      message: 'Please enter a valid email address',
    },
    dob: {
      required: true,
      validate: commonValidators.date,
      message: 'Date of birth is required',
    },
    mobile: {
      required: true,
      validate: commonValidators.mobile,
      message: 'Please enter a valid mobile number',
    },
    member_id: {
      required: isCallcentre.value,
      validate: commonValidators.required,
      message: 'Member ID is required',
    },
    account_number: {
      required: isCallcentre.value,
      validate: commonValidators.required,
      message: 'Account number is required',
    },
  },
)

const toggleDeleteMemberAccountModal = () => {
  deleteModalOpen.value = !deleteModalOpen.value
}

const deleteMemberAccount = (res) => {
  toggleDeleteMemberAccountModal()
  if (res === 'yes') {
    try {
      SuperFundService.deleteSuperFundClient(user.value?.auth0id)
      callCentrePortalStore.reset()
      router.push({ name: 'guidanceSearchUsers' })
      clientStore.logoutClientAccount()
    } catch (e) {
      console.error(e)
      errorToast('Error deleting member account')
    }
  }
}

watchEffect(() => {
  if (user.value) {
    form.first_name = user.value.first_name ?? ''
    form.middle_name = user.value.middle_name ?? ''
    form.last_name = user.value.last_name ?? ''
    form.email = user.value.email ?? ''
    form.dob = user.value.dob ?? ''
    form.mobile = user.value.mobile ?? ''
    form.member_id = user.value.member_id ?? ''
    form.account_number = activeSuperObject.value.account_number ?? user.value.account_number ?? ''
  }
  if (user.value?.auth0id) {
    editable.value = !user.value?.auth0id
  }
})

onBeforeMount(() => {
  if (!user.value?.auth0id) {
    const activeManagedUser = JSON.parse(window.localStorage.getItem('activeManagedUser') ?? '{}')
    if (activeManagedUser?.auth0Id) {
      try {
        loading.value = true
        callCentrePortalStore.getManagedUserData(activeManagedUser.auth0Id)
      } catch (e) {
        console.error(e)
      } finally {
        loading.value = false
      }
    }
  }
})
</script>
