import UserService from '@/services/UserService.ts'
import { computed, ComputedRef, ref } from 'vue'
import { defineStore } from 'pinia'
import SignUpService from '@/services/SignUpService.ts'
import { addSentrySessionData } from '@/lib/SentryHelper.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { userRole } from '@/lib/AuthenticatorPlugin.ts'
import ProfileService from '@/services/ProfileService.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import User from '@/types/api/account/User.ts'
import redirectToExpiredAccountPaywall from '@/composables/users/redirectToPaywall.ts'
import isReturningLegacyUser from '@/composables/users/isReturningLegacyUser.ts'
import {
  useRedirectAdminToWhitelabel,
  useRedirectUserToWhitelabel,
} from '@/composables/users/redirectUserToWhitelabelHome.ts'
import setupUser from '@/composables/users/setupUser.ts'
import { differenceInYears } from 'date-fns/fp'

// User for importing to other stores
export interface IUserStore {
  clearUser: () => void
  fetchUser: () => Promise<User | string | undefined>
  setManagedUserAsUser: (managedUser: User) => void
  fetchLoginCount: () => void
  getUser: User
  getAdmin: User
  loginCount: number
  isLoading: boolean
  userIsAdmin: boolean
  updateUserAuth0Id: (id: number) => void
  cfsClient: boolean
  setUser: (userValue: User) => void
}

export const useUserStore = defineStore('UserStore', () => {
  const { isAdmin } = useCheckUser()
  const sessionStore = useSessionStore()

  const user = ref<User>({} as User)
  const admin = ref<User>()
  const loading = ref(false)
  const loginCount = ref(0)

  const getUser = computed(() => user.value)
  const getAdmin = computed(() => admin.value)
  const isLoading = computed(() => loading.value)

  const userIsAdmin = computed<boolean>(() => admin.value !== undefined)
  const cfsClient = computed(() => {
    return userRole.value === 'client' || userRole.value === 'member'
  })

  const ageNow: ComputedRef<number | undefined> = computed(() => {
    if (!user.value.dob) {
      console.info('[UserStore] userAge: user dob is not set')
      return undefined
    }
    const dob = new Date(user.value.dob)
    const today = new Date()
    return differenceInYears(today, dob)
  })

  const ageThisYear: ComputedRef<number | undefined> = computed(() => {
    if (!user.value.dob) {
      console.info('[UserStore] userAge: user dob is not set')
      return undefined
    }
    const birthYear = new Date(user.value.dob).getFullYear()
    const thisYear = new Date().getFullYear()
    return thisYear - birthYear
  })

  const setUser = (userValue: User) => {
    user.value = userValue
  }

  /**
   * @returns User object
   */
  const fetchUser = async (): Promise<User | string | undefined> => {
    loading.value = true
    try {
      setupUser()
      const res = await UserService.fetchUser()
      const userData: User = res.data.data
      // Set admin or callcentre based user roles to the admin ref
      // advice getting users are always referred to as the User
      if (isAdmin()) {
        admin.value = userData
        if (
          sessionStore.getWhitelabelData.id &&
          userData.whitelabel_id !== sessionStore.getWhitelabelData.id
        ) {
          await useRedirectAdminToWhitelabel(userData.whitelabel_id)
        }
      } else {
        user.value = userData
        if (
          sessionStore.getWhitelabelData.id &&
          userData.whitelabel_id !== sessionStore.getWhitelabelData.id
        ) {
          await useRedirectUserToWhitelabel(user.value.whitelabel_id)
        }
        checkForValidSubscription()
      }

      loading.value = false

      // set sentry session data
      addSentrySessionData({
        email: userData.email ?? '',
        accountId: userData.account_id ?? null,
        auth0Id: userData.auth0id ?? '',
      })

      return userData
    } catch (err) {
      // alert('There was an error, please try to log back in. /user error')
      console.error('error fetching user', err)
      useSessionStore().logout()
    }
  }

  const checkForValidSubscription = () => {
    const user = getUser.value
    if (!sessionStore.getWhitelabelData.subscription_required) return
    if (isReturningLegacyUser() || !user.has_consented) return
    if (user?.subscriptions.length > 0 && user.active_subscription) return

    redirectToExpiredAccountPaywall()
  }

  const setManagedUserAsUser = (managedUser: User) => {
    console.info('[AdminPortal] setting managed user as user...', managedUser)
    user.value = managedUser
  }

  const clearUser = () => {
    loading.value = false
    user.value = undefined
  }

  // Used when creating accounts to link auth0 id to user account
  // Note: 20/05/24 - DTD implemented, this is now deprecated
  // TODO: move to signUp service/store thing later
  const updateUserAuth0Id = (id: number) => {
    return SignUpService.updateAuthIdForUser(id)
  }

  const fetchLoginCount = async () => {
    try {
      const resp = await UserService.getLoginCount()
      loginCount.value = resp?.data.logins_count || 0
    } catch (e) {
      console.error(e)
    }
  }

  // These can stay, maybe add fetch USER profile
  const profile = ref<any>({})
  const fetchProfile = async () => {
    try {
      const res = await ProfileService.getProfile()
      // three datas is not an error, it's the way the api is structured
      const profileData = res.data?.data?.data
      profile.value = profileData
    } catch (err) {
      console.error(err)
    }
  }

  // fetch USER gremlins :)
  const dependents = ref()
  const fetchDependents = async () => {
    try {
      const res = await ProfileService.getDependents()
      const dependentsData = res.data?.data
      dependents.value = dependentsData
    } catch (err) {
      console.error(err)
    }
  }

  return {
    clearUser,
    fetchUser,
    setManagedUserAsUser,
    fetchLoginCount,
    getUser,
    getAdmin,
    loginCount,
    isLoading,
    ageNow,
    ageThisYear,
    userIsAdmin,
    updateUserAuth0Id,
    cfsClient,
    setUser,

    fetchProfile,
    profile,
    fetchDependents,
    getDependents: computed(() => dependents.value),
  }
})
