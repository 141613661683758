<template>
  <!--  TODO: determine what the fuck is up with collapse-->
  <Collapse :default-open="true">
    <template #heading>
      <div class="flex gap-1">
        <h6 class="text-blue-1">Account details</h6>
      </div>
    </template>
    <template #body>
      <Loader v-if="loadingForm" />
      <form v-else @submit.prevent="saveSuper()" id="super-widget-form">
        <div class="grid w-full grid-cols-2 gap-4 md:gap-8">
          <div class="grid grid-cols-1 grid-rows-3 gap-4">
            <div class="flex flex-col col-span-1" v-if="isCallcentre">
              <label for="cfs-account-number" class="capitalize w-fit paragraph-2"
                ><span class="flex flex-row"
                  >Account number
                  <InfoCircle
                    class="flex"
                    message="Your CFS Super account number which starts with 011 or 051 or 065" /></span
              ></label>
              <BaseInput
                type="text"
                validation="numeric"
                :disabled="disabledFields"
                error-message=""
                :value="activeSuperObject.account_number"
                name="cfs-account-number"
                placeholder="Account number"
                class="w-full cfs-guidance-member-input"
                @update:value="
                  (val: string) => {
                    form.account_number = val
                  }
                " />
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <label for="preservation-age" class="capitalize w-fit paragraph-2"
                ><span class="flex flex-row"
                  >Preservation age
                  <InfoCircle class="flex" message="The age you could access your super" /></span
              ></label>
              <BaseInput
                type="text"
                :disabled="true"
                :value="preservationAge"
                class="w-full cfs-guidance-member-input"
                name="preservation-age"
                placeholder="06112345" />
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <label for="super-fund" class="capitalize w-fit paragraph-2"
                ><span class="flex flex-row"
                  >Super fund <InfoCircle class="flex" message="Your current super fund" /></span
              ></label>
              <BaseInput
                type="text"
                :disabled="true"
                :value="activeSuperObject.fund_name"
                class="w-full cfs-guidance-member-input"
                name="super-fund" />
            </div>
          </div>

          <!--SUPER BALANCE-->
          <div class="grid grid-cols-1 grid-rows-3 gap-4">
            <div class="flex flex-col col-span-1">
              <label for="super-balance" class="capitalize w-fit paragraph-2"
                ><span class="flex flex-row"
                  >Super balance
                  <InfoCircle
                    class="flex"
                    message="Your current super balance for this account" /></span
              ></label>
              <BaseInput
                name="super-balance"
                type="currency"
                :disabled="disabledFields"
                :error-message="''"
                :soft-error="getSuperBalanceSoftValidationMessage()"
                :value="activeSuperObject.balance"
                class="w-full cfs-guidance-member-input"
                @update:value="(val: string) => (form.balance = Number(val))" />
            </div>
            <!--MEMBER AGE-->
            <div class="flex flex-col col-span-1 gap-2">
              <label for="member-age" class="capitalize w-fit paragraph-2"
                ><span class="flex flex-row"
                  >Member age
                  <InfoCircle class="flex" message="Your current age according to your DOB" /></span
              ></label>
              <BaseInput
                name="member-age"
                type="text"
                :disabled="true"
                :value="calculateAge(userData.dob)"
                class="w-full cfs-guidance-member-input" />
            </div>
          </div>
        </div>

        <div
          class="w-full flex flex-col gap-6 mt-[20px]"
          v-if="activeSuperObject.chant_west_product_id">
          <div
            v-for="(item, itemIndex) in form.allocation"
            :class="item.deleted_at ? 'hidden' : ''"
            class="flex flex-col md:flex-row items-end gap-6"
            :key="itemIndex">
            <div class="flex flex-col w-100% gap-4" v-if="isAdmin()">
              <InputLabel :label="`Investment option ${itemIndex + 1}`" />
              <Dropdown
                :items="investmentOptions"
                placeholder="Select your investment option"
                type="investmentOptions"
                class="text-left w-100%"
                @selected="
                  (investmentOption) =>
                    setInvestmentAllocationItemInformation(investmentOption, itemIndex)
                "
                :existing-item="{
                  label: item.chant_west_investment_name ?? '',
                  value: item.chant_west_investment_id,
                }" />
            </div>
            <!-- Conditional text required for websocket update as autocomplete stubborn -->
            <!--            remove v-else -->
            <div v-else class="flex flex-col gap-2 w-75%">
              <label for="member-age" class="capitalize w-fit paragraph-2">{{
                `Investment option ${itemIndex + 1}`
              }}</label>
              <BaseInput
                :disabled="cfsClient"
                name="super-fund-allocation"
                type="text"
                :value="item.chant_west_investment_name"
                class="w-full cfs-guidance-member-input" />
            </div>
            <div class="flex flex-col col-span-1 gap-2 items-center">
              <label for="CFS_details_id" class="capitalize w-full paragraph-2 flex"
                >Allocation</label
              >
              <InputPercentage
                name="allocation-percentage"
                :disabled="disabledFields"
                :data-test="`investmentOptionAllocation${itemIndex}`"
                :value="item.percent_allocation"
                @update:value="(val: number) => (item.percent_allocation = val)"
                :error-message="totalAllocationPercentage > 100 ? 'Total must be 100%' : ''" />
            </div>
            <div
              v-if="itemIndex === 0 && !cfsClient"
              class="w-[40px] h-[40px] flex justify-center text-white bg-otivo-blue hover:bg-blue-1 rounded items-center cursor-pointer"
              @click="form.allocation.push(superAllocationTemplate())">
              <AdditionIcon style="width: 40px" />
            </div>
            <div
              v-else-if="!cfsClient"
              class="md:w-[40px] h-[40px] flex justify-center text-white bg-otivo-blue hover:bg-blue-1 rounded items-center cursor-pointer"
              @click="deleteInvestmentAllocation(itemIndex)">
              <SubtractionIcon style="width: 40px" />
            </div>
          </div>
          <!--          <InputError class="text-center" :error="superWidgetErrors.superInvestmentOptions" />-->
        </div>
        <div class="flex mt-20 justify-center gap-[20px]" v-if="guidanceClientConsent">
          <!--          v-if="guidanceClientConsent && !waitingOnTermDepositConsent">-->
          <OtivoButton
            :large-text="true"
            class="w-72"
            colour="white"
            data-test="saveBtn"
            :loading="saving"
            :disabled="disableButtons">
            Save
          </OtivoButton>
          <!-- type="button" is to prevent it from submitting the form-->
          <OtivoButton
            :large-text="true"
            class="w-72"
            colour="red"
            type="button"
            data-test="optimiseBtn"
            @click="emit('getAdvice')"
            :loading="optimiseLoading"
            :disabled="disableOptimiseButton">
            Optimise now
          </OtivoButton>
        </div>
        <div v-else-if="!cfsClient" class="m-auto text-center mt-20">
          <OtivoButton
            :large-text="true"
            class="m-auto w-72"
            colour="white"
            type="submit"
            data-test="saveBtn"
            :disabled="disableButtons"
            :loading="saving">
            Save
          </OtivoButton>
        </div>
      </form>
    </template>
  </Collapse>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, reactive, ref } from 'vue'
import { SuperFundAllocationNameType } from '@/types/CallcentrePortalTypes.ts'
import OtivoButton from '@/components/OtivoButton.vue'
import Collapse from '@/components/SaveAndCollapse/Collapse.vue'
import Loader from '@/components/Loader/Loader.vue'
import { useUserStore } from '@/store/pinia/UserStore'
import { sendGuidanceOnboardEmail } from '@/store/pinia/adminPortal/EmailManager.ts'
import { useToast } from '@/composables/useToast.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import calculateAge from '@/composables/users/calculateAge.ts'
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import getSuperFundInvestmentOptions from '@/composables/superHelpers/getSuperFundInvestmentOptions.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'
import InputLabel from '@/components/BasicInputs/InputLabel.vue'
import superAllocationTemplate from '@/composables/superHelpers/superAllocationTemplate.ts'
import InvestmentOption from '@/types/api/super/InvestmentOption.ts'
import SubtractionIcon from '@/components/icons/SubtractionIcon.vue'
import AdditionIcon from '@/components/icons/AdditionIcon.vue'
import InputPercentage from '@/components/BasicInputs/InputPercentage.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'

/*********************************************************
 *\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
 * NOTE:
 * THIS IS ONLY USED FOR CFS CONFIRMING THEIR DETAILS NOW
 * DEPRECATED
 * \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
 * *******************************************************/

interface Props {
  disabledFields?: boolean
  disableOptimise?: boolean
  loadingForm?: boolean
  optimiseLoading?: boolean
  preservationAge: number
}

const props = withDefaults(defineProps<Props>(), {
  disabledFields: false,
  disableOptimise: false,
  loadingForm: false,
  optimiseLoading: false,
})
const userStore = useUserStore()
const superStore = useSuperStore()
const sessionStore = useSessionStore()
const { isAdmin } = useCheckUser()
const { successToast, errorToast } = useToast()

const emit = defineEmits(['getAdvice'])

const isCallcentre = computed(() => sessionStore.getWhitelabelData.callcentre_portal)
const cfsClient = computed(() => userStore.cfsClient)
const userData = computed(() => userStore.getUser)
const activeSuperObject = computed(() => superStore.getActiveSuperObject)
const allocationItems = computed(() => activeSuperObject.value.investments)

//TODO: refactor - consent
const guidanceClientConsentEmailSent = computed(() => false)
const guidanceClientConsent = computed(() => false)

const saving = ref(false)
const disableButtons = ref(false)

const totalAllocationPercentage = computed(() => {
  const investmentOptions = form.allocation.filter((item: InvestmentOption) => !item.deleted_at)
  return investmentOptions.reduce((acc, curr) => acc + curr.percent_allocation, 0)
})
const form = reactive({
  account_number: activeSuperObject.value.account_number,
  balance: activeSuperObject.value.balance ?? 0,
  allocation: activeSuperObject.value.investments,
})

const disableOptimiseButton = computed(() => {
  const temp =
    activeSuperObject.value.investments[0].chant_west_investment_id === null ||
    !(activeSuperObject.value.balance > 0)
  return (
    temp ||
    activeSuperObject.value.id === '' ||
    allocationItems.value.length === 0 ||
    props.disableOptimise ||
    disableButtons.value
  )
})

const setInvestmentAllocationItemInformation = (
  value: SuperFundAllocationNameType,
  index: number,
) => {
  const investment = form.allocation[index]
  investment.chant_west_investment_name = value.label
  investment.chant_west_investment_id = Number(value.value)
}

const saveSuper = async () => {
  // handle create ( if/when needed )
  // use saving so only the 'save' button shows a loading state while saving... insert eye-roll here
  saving.value = true
  try {
    await superStore.updateSuperObject(activeSuperObject.value.id, form)
    successToast('Super updated!')
  } catch (e) {
    errorToast('Failed to update super')
    console.error(e)
  }
  saving.value = false
}

const getSuperBalanceSoftValidationMessage = () => {
  const balance = form.balance
  if ((balance > 10000000 || balance < 1000) && balance !== null) {
    return 'Please check balance'
  }
  return ''
}

const deleteInvestmentAllocation = (index: number) => {
  if (!form.allocation[index].id) form.allocation.splice(index, 1)
  else softDeleteInvestmentOption(index)
}

const softDeleteInvestmentOption = (index: number) => {
  form.allocation[index].deleted_at = new Date().toISOString()
}

const investmentOptions = ref<SuperFundAllocationNameType[]>([])
onBeforeMount(async () => {
  // check to see if we have investment options in the store for the primary super already
  if (activeSuperObject.value.chant_west_product_id) {
    investmentOptions.value = await getSuperFundInvestmentOptions(
      activeSuperObject.value.chant_west_product_id,
    )
  }
})
</script>

<style lang="scss" scoped>
@use '@/assets/css/variables.scss';
</style>
