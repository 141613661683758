<template>
  <div id="otivo-home" class="min-h-75vh bg-white">
    <LandingPageModule
      class="items-center"
      :show-button="true"
      @button-clicked="signUp"
      :landing-page-content="landingPageModuleContent()">
      <template #svgComponent>
        <img
          v-if="isIOS()"
          :src="OtivoHomeHeroStatic"
          class="max-w-[350px]"
          alt="lisa-shaking-phone" />
        <video
          preload="auto"
          v-else
          autoplay
          loop
          muted
          playsinline
          class="max-w-[350px] -mt-8"
          :src="OtivoHomeHero" />
      </template>
    </LandingPageModule>
    <RegularCurve fill="white" class="bg-blue-6 h-6 w-full" :rotate="true" />

    <GettingStartedTiles class="py-[80px] bg-blue-6" />
    <div v-if="!isPartnerSite">
      <RegularCurve fill="blue-6" class="bg-blue-5 h-6 w-full" :rotate="true" />
      <div class="bg-blue-5 w-full">
        <ServiceCards :data="HomepageSlideshowData" />
      </div>

      <div class="px-5 md-px-auto">
        <TrustedBy class="bg-white py-[50px]" />
      </div>
      <RegularCurve fill="otivo-blue" class="bg-white h-6 w-full" :rotate="false" />
    </div>
    <RegularCurve v-else fill="otivo-blue" class="bg-white h-6 w-full" :rotate="false" />

    <HowOtivoWorks class="py-[60px]" :how-otivo-works-content="howItWorks">
      <template #modal>
        <p class="w-[280px] pt-[20px] md:pt-0 m-auto md:w-auto intro-1 text-white">
          {{ howItWorks.tagline }}
          <span @click="showHowWeWorkItOutModal" class="underline cursor-pointer">
            {{ howItWorks.taglineLink }}
          </span>
        </p>
      </template>
      <template #buttons>
        <OtivoButton
          class="mt-[20px] w-full sm:w-auto m-auto lg:ml-0"
          colour="red"
          @click="signUp"
          data-test="homeBtnTrial">
          {{ ctaButtonText }}
        </OtivoButton>
      </template>
    </HowOtivoWorks>
    <RegularCurve fill="otivo-blue" class="bg-blue-5 h-6 w-full" :rotate="true" />
    <FlexFinancialFitness class="py-[50px] bg-blue-5" />
    <RegularCurve fill="blue-5" class="bg-white h-6 w-full" :rotate="true" />

    <ThreeTileDisplay
      top-curve-fill="white"
      circle-colour="blue-6"
      :icon-boxes="threeTileDisplay.iterableItems" />
    <div class="md:hidden m-auto flex flex-col items-center mt-[20px]">
      <h5 class="text-blue-2 bold">AS SEEN IN</h5>
      <MediaLogos class="mt-[35px]" />
    </div>
    <RegularCurve fill="grey-1" class="h-6 w-full mt-[40px]" />

    <Awards
      class="bg-grey-1 text-white py-[50px] md:py-[70px]"
      :color="{
        svgImageBackgroundFill: 'grey-1',
        svgLineFill: 'white',
        textColour: 'white',
      }" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import Awards from '@/components/Footer/Awards.vue'
import LandingPageModule from '@/components/BrandComponents/LandingPageModule.vue'
import OtivoHomeHero from '@/assets/animated/lisa-shaking-phone.webm?url'
import OtivoHomeHeroStatic from '@/assets/img/lisa-shaking-phone.webp?url'
import HowOtivoWorks from '@/components/BrandComponents/Otivo/HowItWorks/HowOtivoWorks.vue'
import ThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import FlexFinancialFitness from '@/components/BrandComponents/Otivo/FlexFinancialFitness.vue'
import RegularCurve from '@/components/Shapes/RegularCurve.vue'
import GettingStartedTiles from '@/components/Dashboard/GettingStartedTiles.vue'
import HowWeWorkItOutModal from '@/components/BrandComponents/Otivo/HowWeWorkItOutModal.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import HomepageSlideshowData from '@/views/Otivo/Brand/Solutions/HomepageSlideshowData.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import ServiceCards from '@/components/BrandComponents/ServiceCards.vue'
import { useAuth0 } from '@/lib/AuthenticatorPlugin.ts'
import TrustedBy from '@/components/BrandComponents/Otivo/TrustedBy/TrustedBy.vue'
import MediaLogos from '@/components/SVGS/MediaLogos.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { useHead } from '@unhead/vue'

const modalStore = useModalStore()
const sessionStore = useSessionStore()
const whitelabel = computed(() => sessionStore.getWhitelabelData)

useHead({
  title: "Otivo's digital advice: Helping Australians be better off",
})
const showHowWeWorkItOutModal = () => {
  modalStore.openModal(HowWeWorkItOutModal, {})
}

const ctaButtonText = computed(() =>
  whitelabel.value.id === 1 ? 'Start free trial' : 'Get started',
)

const landingPageModuleContent = () => {
  if (whitelabel.value.identifier === 'NESS_SUPER') return nessSuperModuleContent
  return {
    heading: '',
    subHeading:
      'We\'re making financial advice <span class="font-bold">more affordable</span> and <span class="font-bold">more accessible</span> to <span class="font-bold">more Australians</span>',
    buttonText: ctaButtonText.value,
    iterableItems: [],
  }
}

const nessSuperModuleContent = {
  heading: 'Otivo is now available for NESS Super members',
  subHeading:
    "Like us, NESS Super is on a mission to empower their members to take control of their financial future. To give them a hand, they've turned to us to provide licensed financial advice to their members to help them be better off.",
  buttonText: ctaButtonText.value,
  iterableItems: [],
}

const threeTileDisplay = {
  iterableItems: [
    {
      heading: 'Easy as',
      body: 'Easy-to-implement advice available 24\/7 on a browser near you.',
    },
    {
      heading: 'No strings attached',
      body: 'Budget-friendly advice with no upselling and no commissions.',
    },
    {
      heading: 'Seriously secure',
      body: 'Licensed advice, bank-grade security and data stored in Australia.',
    },
  ],
}

const howItWorks = {
  heading: 'Financial advice at your <span class="svg-underline-wide">fingertips</span>',
  subHeading:
    'Otivo helps thousands of Australians be better off by an average of <span class="font-bold">$371K</span> at retirement.',
  tagline: 'Yep, you read that right.',
  taglineLink: 'Here’s how we do it.',
  footer:
    'And because it all lives online, Otivo’s advice automatically updates to whatever new is happening in your world.',
  buttonText: ctaButtonText.value,
  iterableItems: [
    {
      heading: 'Protect the things you love',
      body: "We'll help you get the personal insurance cover you need for peace of mind.",
    },
    {
      heading: 'Maximise your super growth',
      body: "We'll calculate how much you can comfortably contribute each year to super without hitting your caps.",
    },
    {
      heading: 'Retire years earlier',
      body: 'We’ll project what your future looks like, then help you optimise your super so you can retire in style.',
    },
    {
      heading: 'Pay down your debts faster',
      body: 'We’ll let you know which debts to tackle first, then show you how to pay them down faster.',
    },
    {
      heading: 'Keep track of your money',
      body: 'Otivo also comes with heaps of easy-to-understand resources to help you stay on top of your finances.',
    },
  ],
}

const isPartnerSite = computed(() => {
  return (
    whitelabel.value.identifier !== 'COLONIAL_FIRST_STATE' &&
    whitelabel.value.identifier !== 'OTIVO'
  )
})

const auth0 = useAuth0()
const signUp = () => auth0.createAccount()
const isIOS = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent) && !/android/.test(userAgent)
}
</script>

<style lang="scss" scoped>
.bottom-curve {
  width: 100%;
  height: 40px;
  background: white;
  clip-path: ellipse(50% 75% at 50% 0%);
}
</style>
