import { Ref } from 'vue'
import getApiInstance from '@/services/Api'

const api = getApiInstance()

export type WealthHealthQuestionType = {
  id: number
  question: string
  score: Ref<number | null>
  order: number
}

export type WealthHealthScoreResponse = {
  latest_score: number
  previous_score: number
  platform_average_score: number
  latest_answers: Array<WealthHealthCheckResponseType>
  last_five_scores: Array<WealthHealthCheckScore>
  enable_retaking_test: boolean
}

export type WealthHealthCheckResponseType = {
  question: string
  answer: number
}

export type WealthHealthCheckScore = {
  date: string
  score: number
}

export default {
  fetchQuestions() {
    return api.Get<Array<WealthHealthQuestionType>>('/v3/account/wealth-health-check/questions')
  },

  fetchWealthHealthCheck() {
    return api.Get<WealthHealthScoreResponse>('/v3/account/wealth-health-check')
  },

  storeWealthHealthCheckResponse(payload: Array<{ question_id: number; answer: number }>) {
    return api.Post('/v3/account/wealth-health-check', {
      response: payload,
    })
  },
}
