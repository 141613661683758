import { reactive, ref } from 'vue'
import { defineStore } from 'pinia'
import WealthHealthCheckService, {
  WealthHealthCheckResponseType,
  WealthHealthCheckScore,
  WealthHealthQuestionType,
} from '@/services/v3/WealthHealthCheckService.ts'
import { useToast } from '@/composables/useToast.ts'
import { useAccountFlagsStore } from '@/store/pinia/AccountFlagsStore.ts'

export const useWealthHealthCheckStore = defineStore('wealthHealthCheckStore', () => {
  const { errorToast } = useToast()

  const questions = ref([] as Array<WealthHealthQuestionType>)
  const scores = reactive<{
    latest_score: number | null
    previous_score: number | null
    platform_average_score: number | null
  }>({
    latest_score: null,
    previous_score: null,
    platform_average_score: null,
  })

  const loading = ref(false)
  const showScore = ref(false)
  const showQuestions = ref(false)
  const canRetakeTest = ref(false)
  const showPreamble = ref(false)
  const latestAnswers = ref<Array<WealthHealthCheckResponseType>>([])
  const healthScores = ref<Array<WealthHealthCheckScore>>([])

  const fetchQuestions = () => {
    loading.value = true
    WealthHealthCheckService.fetchQuestions()
      .then((res) => {
        questions.value = res?.data
          ?.map((question) => ({
            id: question.id,
            question: question.question,
            order: question.order,
            score: ref(null),
          }))
          .sort((a, b) => a.order - b.order)
      })
      .catch((e) => {
        console.error(e)
        errorToast('Failed to fetch questions')
      })
      .finally(() => {
        loading.value = false
      })
  }

  const fetchScores = () => {
    loading.value = true
    WealthHealthCheckService.fetchWealthHealthCheck()
      .then((res) => {
        showScore.value = res?.data?.latest_score !== null
        canRetakeTest.value = res?.data?.enable_retaking_test
        scores.latest_score = res?.data?.latest_score
        scores.previous_score = res?.data?.previous_score
        scores.platform_average_score = res?.data?.platform_average_score

        latestAnswers.value = res?.data?.latest_answers
        healthScores.value = res?.data?.last_five_scores
      })
      .catch((e) => {
        console.error(e)
        errorToast('Failed to fetch your score')
      })
      .finally(() => {
        loading.value = false
      })
  }

  const storeScore = (
    answers: Array<{
      question_id: number
      answer: number
    }>,
  ) => {
    const accountFlags = useAccountFlagsStore()
    loading.value = true
    WealthHealthCheckService.storeWealthHealthCheckResponse(answers)
      .then((_res) => {
        canRetakeTest.value = false
        showQuestions.value = false
        fetchScores()

        const hasCompletedWealthHealthCheck = accountFlags.getFlag(
          'appNotifications',
          'has_acknowledged_wealth_health_check',
        )
        if (!hasCompletedWealthHealthCheck) {
          accountFlags.updateAccountFlags(
            'appNotifications',
            'has_acknowledged_wealth_health_check',
          )
        }
        
      })
      .catch((e) => {
        console.error(e)
        errorToast('Failed to store your score')
      })
      .finally(() => {
        loading.value = false
      })
  }

  return {
    loading,
    showScore,
    showQuestions,
    showPreamble,
    canRetakeTest,
    questions,
    scores,
    latestAnswers,
    healthScores,
    fetchScores,
    storeScore,
    fetchQuestions,
  }
})
