import getApiInstance from '@/services/Api.ts'

const api = getApiInstance()

export default {
  createPortalStaffMember(payload) {
    return api.Post('/superfund/users', payload)
  },

  updatePortalStaffMember(payload) {
    return api.Put(`/superfund/users/${payload.id}`, payload)
  },

  deleteStaffMember(id: string) {
    return api.Delete(`/superfund/users/${id}`)
  },

  restoreStaffMember(id: string) {
    return api.Put(`/superfund/restore-users/${id}`)
  },

  getAllPortalStaff(sortObject) {
    const payload = { params: sortObject }
    return api.Get('/superfund/users', payload)
  },
}
