<template>
  <section class="flex flex-row gap-x-5 justify-between">
    <template v-for="(tile, index) in tiles" :key="tile.label">
      <div
        v-if="!loading"
        class="w-1/5 bg-white border border-blue-4 rounded p-5 flex flex-col gap-y-2.5">
        <div class="flex justify-between items-start relative">
          <span class="button-4">{{ tile.label }}</span>

          <BaseInfoCircle
            class="-mt-1"
            v-if="!tile.label.includes('Total')"
            :info="questions[index - 1]?.question" />
        </div>
        <h3 class="text-blue-1">{{ tile.value }}</h3>
      </div>
    </template>
    <template v-for="tile in 5" :key="tile">
      <div
        v-if="loading"
        class="mx-auto w-full max-w-sm rounded-md border border-blue-4 bg-white p-5 rounded gap-y-2.5">
        <div class="flex flex-col animate-pulse gap-y-2.5">
          <div class="h-2.5 rounded bg-grey-4"></div>
          <div class="h-10 w-12 rounded bg-grey-4"></div>
        </div>
      </div>
    </template>
  </section>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import BaseInfoCircle from '@/components/Inputs/BaseInfoCircle.vue'
import { useWealthHealthCheckStore } from '@/store/pinia/adminPortal/wealthHealthCheckStore.ts'

type Props = {
  tiles: Array<{ label: string; value: string }>
  loading: boolean
}
defineProps<Props>()

const wealthHealthCheckStore = useWealthHealthCheckStore()
const questions = computed(() => wealthHealthCheckStore.questions)
onMounted(() => {
  wealthHealthCheckStore.fetchQuestions()
})
</script>

<style scoped></style>
