<template>
  <div class="bg-blue-6 w-100% h-[100vh] relative flex flex-col">
    <div class="bg-midnight flex">
      <div class="xl:w-auto flex justify-center pl-20">
        <img
          v-if="!whitelabel.joint_logo"
          src="@/assets/img/otivo-logo-white-text.png"
          class="max-w-[130px] py-10"
          alt="otivo-logo" />
        <logo v-else />
      </div>
    </div>
    <div class="flex relative flex-grow">
      <BeatLoader
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        color="var(--otivo-blue)"
        size="25px" />
    </div>
    <div class="flex bg-midnight py-10"></div>
  </div>
</template>

<script setup lang="ts">
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import { onMounted } from 'vue'
import { handleLoginCallback } from '@/router/routeGuards.ts'
import { useRoute, useRouter } from 'vue-router'
import Logo from '@/components/Logo/Logo.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'

const route = useRoute()
const router = useRouter()
const sessionStore = useSessionStore()
const whitelabel = sessionStore.getWhitelabelData

onMounted(() => {
  if (route && route.query.code && route.query.state) handleLoginCallback(router)
})
</script>

<style scoped></style>
