<template>
  <div>
    <div>
      <h3 class="text-grey-1">{{ userData.first_name }}, {{ pageContent.heading }}</h3>
      <p class="intro-1 text-grey-1 pt-[20px]">
        {{ pageContent.subHeading }}
      </p>
    </div>
    <Loader v-if="soaDownloading" />
    <div
      class="w-full max-h-[400px] md:max-h-[600px] border border-otivo-blue overflow-hidden md:overflow-scroll gap-10 flex flex-col items-center bg-white py-[20px] md:py-[40px] px-[40px] md:px-[80px]">
      <OtivoLogo type="black_vertical" />
      <div class="paragraph-2 mt-[20px]">
        <h3 class="font-bold">Statement of Advice</h3>
        <h6 class="font-bold pt-[10px]">
          Prepared for {{ userData.first_name }} {{ userData.last_name }}
        </h6>
        <p class="pt-[10px]">Date of advice: {{ todaysDate }}</p>
        <br /><br /><br />
        <p class="pt-[10px]">Prepared by {{ whitelabelDetails.provider }}</p>
        <p class="pt-[10px]">
          Australian Financial Services Licence no. {{ whitelabelDetails.afsl }}, ABN
          {{ whitelabelDetails.abn }}
        </p>
        <p class="pt-[10px]">
          Contact Otivo via email: <a href="mailto:info@otivo.com">info@otivo.com</a>
        </p>
      </div>
    </div>
    <div class="w-100% md:w-fit flex flex-col gap-[16px] mt-[40px]">
      <OtivoButton
        size="large"
        @click="downloadSOAButton"
        colour="white"
        data-test="downloadSoaBtn"
        :loading="soaDownloading">
        {{ pageContent.buttonText }}
      </OtivoButton>
      <OtivoButton size="large" @click="onNext" colour="blue" data-test="nextBtn">
        {{ pageContent.buttonText2 }}
      </OtivoButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import SuperFundService from '@/services/SuperFundService.ts'
import OtivoLogo from '@/components/icons/OtivoLogo.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import Loader from '@/components/Loader/Loader.vue'
import { ComponentContentKeysType } from '@/types/GlobalTypes'
import { downloadSOA } from '@/lib/WhiteLabelHelpers'
import { formatDate } from '@vueuse/core'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useStore } from 'vuex'
import { useToast } from '@/composables/useToast.ts'

type Props = {
  pageContent: ComponentContentKeysType
  onNext: () => void
}
defineProps<Props>()

const userData = computed(() => useUserStore().getUser)
const store = useStore()
const whitelabelDetails = computed(() => store.getters.getAFSLDetailsForWhiteLabel)
const date = new Date()
const todaysDate = formatDate(date, 'MMMM D, YYYY')

const { successToast, errorToast } = useToast()

const soaDownloading = ref(false)
const downloadSOAButton = async () => {
  soaDownloading.value = true
  SuperFundService.generateSuperFundSoa({ auth0id: userData.value.auth0id })
    .then(() => {
      SuperFundService.downloadSoA({ clientAccountId: userData.value.account_id })
        .then((res) => {
          if (res?.data) {
            downloadSOA(res.data.pdf)
            successToast('Statement of Advice downloaded successfully')
          }
        })
        .catch((err) => {
          console.error('Error downloading SoA', err)
          errorToast(
            'We are having some trouble generating your Statement of Advice. Please try again later.',
          )
        })
    })
    .catch((err) => {
      console.log('err', err)
    })
    .finally(() => {
      soaDownloading.value = false
    })
}
</script>
