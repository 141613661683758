import getApiInstance from '@/services/Api.ts'
import { User } from '@/lib/AuthenticatorPlugin.ts'

let accountStatus: AccountStatus

export default async (auth0User: User): Promise<{ name: string | undefined }> => {
  accountStatus = (await getUserState()) as AccountStatus

  // first time user
  if (!accountStatus) return { name: 'UserDetailsForm' }

  // returning legacy user
  if (auth0User.legacy_user && !accountStatus.dtd_member) return { name: 'UserDetailsForm' }

  //  if user didnt create themselves && they're a superfund member then get outta here
  // todo: have flag on metadata 'created_by: superfund'
  if (!accountStatus.dtd_member && auth0User.user_role === 'member') return { name: undefined }

  // Where are we up too
  return new Promise((resolve) => resolve(getAccountCreationStep()))
}

type AccountStatus = {
  has_account: boolean
  has_verified_mobile: boolean
  is_super_fund_member: boolean
  has_member_id: boolean
  has_super_account: boolean
  has_super_account_number: boolean
  has_fee_consent: boolean
  dtd_member: boolean
}

const getAccountCreationStep = (): { name: string | undefined } => {
  if (!accountStatus.has_account) return { name: 'UserDetailsForm' }
  // Super fund config checks
  if (accountStatus.is_super_fund_member) {
    if (!accountStatus.has_member_id) return { name: 'CaptureFundMemberId' }
    if (!accountStatus.has_super_account || !accountStatus.has_super_account_number)
      return { name: 'CaptureSuperAccountNumber' }
    if (!accountStatus.has_fee_consent) {
      return { name: 'SuperFundDisclaimer' }
    }
  } else {
    if (!accountStatus.has_super_account) return { name: 'SelectSuperFund' }
    if (!accountStatus.has_fee_consent) return { name: 'TermsAndConditionsForm' }
  }
  return { name: undefined }
}

const getUserState = async () => {
  const api = getApiInstance()
  try {
    const res: { data: AccountStatus } = await api.Get('/v3/valid-user')
    return res.data
  } catch (e) {
    if (e.response?.status === 404) return
    throw e
  }
}
