<template>
  <button
    class="paragraph-2 p-[10px] text-grey-2 border-b border-blue-5 w-full text-left"
    :class="disabled ? 'text-grey-3' : 'text-blue-1 hover:text-blue-1'"
    :disabled="disabled"
    @click="generateSoA">
    {{ showDownloadPDFButton === 'generating' ? 'Generating...' : 'Statement of Advice' }}
  </button>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'
import { useGuidanceClientStore } from '@/store/pinia/GuidanceClientStore.ts'
import SuperFundService from '@/services/SuperFundService.ts'
import { downloadSOA } from '@/lib/WhiteLabelHelpers'
import { ModuleStatusesType } from '@/store/pinia/ModuleStatusesStore.ts'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { useToast } from '@/composables/useToast.ts'
import { ShowDownloadPDFButtonOptionsType } from '@/types/CallcentrePortalTypes.ts'

const clientStore = useGuidanceClientStore()
const userStore = useUserStore()
const user = computed(() => userStore.getUser)
const moduleStatuses = computed<ModuleStatusesType>(() => clientStore.getModuleStatuses)
const emit = defineEmits(['click'])
const showDownloadPDFButton = ref<ShowDownloadPDFButtonOptionsType>('inactive')
const disabled = computed(() => showDownloadPDFButton.value !== 'complete')
const { successToast, errorToast } = useToast()

watchEffect(() => {
  const values = Object.keys(moduleStatuses.value).map((key: string) => {
    if (key === 'personalInsurance') {
      // should only need to check 1 as they'll all graduate from infoNeeded at the same time
      return moduleStatuses.value[key].incomeCover
    }
    return moduleStatuses.value[key]
  })
  if (values.find((value: string) => value !== 'infoNeeded'))
    showDownloadPDFButton.value = 'complete'
})

const generateSoA = async () => {
  showDownloadPDFButton.value = 'generating'
  const auth0Id = user.value.auth0id

  await SuperFundService.generateSuperFundSoa({ auth0id: auth0Id })
    .then(() => {
      triggerSOADownload()
    })
    .catch((err) => {
      console.error('Error Generating SoA', err)
      errorToast(
        'We are having some trouble generating your Statement of Advice. Please try again later.',
      )
    })
    .finally(() => {
      showDownloadPDFButton.value = 'complete'
    })
}

const triggerSOADownload = () => {
  const userAccountId = user.value.account_id ?? null
  if (!userAccountId) {
    console.error('Failed to download SOA') /**/
    errorToast(
      'We are having some trouble downloading your Statement of Advice. Please try again later.',
    )
    return
  }
  SuperFundService.downloadSoA({ clientAccountId: userAccountId })
    .then((res) => {
      if (res?.data) {
        downloadSOA(res.data?.pdf)
        successToast('Statement of Advice downloaded successfully')
        emit('click')
      }
    })
    .catch((err) => {
      errorToast(
        'We are having some trouble downloading your Statement of Advice. Please try again later.',
      )
      console.error('Error downloading SoA', err)
    })
    .finally(() => {
      showDownloadPDFButton.value = 'complete'
    })
}
</script>

<style scoped></style>
