<template>
  <section v-if="content" class="transition" :class="content ? 'h-auto' : 'h-0'">
    <div v-if="!state.selectedCurrentOption">
      <h3 class="text-grey-1" v-html="content.confirm_heading"></h3>
      <p class="intro-1 text-grey-1 pt-[20px]">
        {{ content.confirm_preamble }}
      </p>
    </div>
    <div v-else>
      <!--      IMPLEMENT_ADVICE_SUPER_INVESTMENT_CONFIRM_NO_CHANGE_BLOCK-->
      <h3 class="text-grey-1">You've chosen to stay with your current investment</h3>
      <p class="intro-1 text-grey-1 pt-[20px]">Can you please confirm this is correct?</p>
    </div>
    <!--    Change Table-->
    <ClientConfirmationChangeSuperText
      class="mt-[10px]"
      :investments="activeSuper.investments"
      :requested-allocation="requestedAllocation"
      :selected-current-option="state.selectedCurrentOption" />
    <!--    END TABLE -->
    <div v-if="!state.selectedCurrentOption">
      <div v-if="!refuseCashChanges && cashAllocation" class="paragraph-1 my-[20px]">
        {{ pageContent.body }}
      </div>
      <br />
      <!--    TAG:  PDS_NOTICE -->
      <ClientConfirmationChangeSuperTerms :content="content.pds_notice" :super="activeSuper" />
      <!--      END TAG -->
      <br />
      <!--      SUPERFUND_SWITCH_CONFIRM_DECLARATION_BLOCK-->
      <ClientConfirmationChangeSuperMemberDeclaration
        v-if="declarationBlockContent.declaration_1 !== ''"
        :content="declarationBlockContent"
        :member-declaration="memberDeclaration"
        :product-name="activeSuper.fund_name"
        @on-toggle="memberDeclaration = $event" />
      <!--      END-->
    </div>

    <div class="w-100% md:w-fit flex flex-col gap-[16px] mt-[40px]">
      <OtivoButton
        size="large"
        @click="alertBoxResponse"
        colour="blue"
        data-test="proceedBtn"
        :loading="updatingSuper"
        :disabled="
          declarationBlockContent.declaration_1 !== '' &&
          !memberDeclaration &&
          !state.selectedCurrentOption
        ">
        {{ pageContent.buttonText }}
      </OtivoButton>
      <!-- If they select current option the close button should close the whole modal        -->
      <OtivoButton size="large" @click="cancelAdvice" colour="white" data-test="cancelBtn">
        {{ pageContent.buttonText2 }}
      </OtivoButton>
    </div>
  </section>
  <BeatLoader v-else color="var(--otivo-blue)" />
</template>

<script setup lang="ts">
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import { computed, onBeforeMount, ref } from 'vue'
import { cloneObject, convertArrayPayloadToSnakeCase, convertPayloadToSnakeCase } from '@/utilities'
import SuperFundService from '@/services/SuperFundService.ts'
import { useUserStore } from '@/store/pinia/UserStore'
import ClientConfirmationChangeSuperText from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationChangeSuperText.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { ComponentContentKeysType } from '@/types/GlobalTypes'
import ClientConfirmationChangeSuperMemberDeclaration from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationChangeSuperMemberDeclaration.vue'
import ClientConfirmationChangeSuperTerms from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationChangeSuperTerms.vue'
import { useIntrafundAdviceStore } from '@/store/pinia/IntrafundAdviceStore'
import { state } from '@/components/CFS/IntrafundInvestment/superInvestmentsState.ts'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'
import moduleContentService from '@/services/ModuleContentService.ts'
import { superInvestmentRecommendationHandlers } from '@/components/CFS/IntrafundInvestment/Utils/superInvestmentRecommendationHandlers.ts'
import { userRole } from '@/lib/AuthenticatorPlugin.ts'
import { useCheckUser } from '@/composables/users/checkUser.ts'

type Props = {
  refuseCashChanges: boolean
  pageContent: ComponentContentKeysType
  selectedCurrentOption: boolean
  cancelAdvice: () => void
}
const props = defineProps<Props>()
const emit = defineEmits(['cancelAdvice'])

const content = ref()
const declarationBlockContent = ref()
onBeforeMount(async () => {
  const { getBlockTags } = moduleContentService()
  const res = await getBlockTags([
    'implement_advice_super_investment_confirm_block',
    'superfund_switch_confirm_declaration_block',
  ])
  content.value = { ...res.data.implement_advice_super_investment_confirm_block }
  declarationBlockContent.value = { ...res.data.superfund_switch_confirm_declaration_block }
})

const userStore = useUserStore()
const superStore = useSuperStore()
const moduleStatusesStore = useModuleStatusesStore()
const intrafundAdviceStore = useIntrafundAdviceStore()

const { isUser } = useCheckUser()
const { fetchSuperInvestmentAdvice } = superInvestmentRecommendationHandlers(
  userStore,
  superStore,
  isUser,
)
const userData = computed(() => userStore.getUser)
const activeSuper = computed(() => superStore.getActiveSuperObject)
const superWidgetGraphData = computed(() => intrafundAdviceStore.getSuperWidgetGraphData)
const requestedAllocation = computed(() => intrafundAdviceStore.getRequestedAllocation)
const updatingSuper = ref(false)
const memberDeclaration = ref(false)

// Needs to search through all the allocations in case they are a cash option
const cashAllocation = activeSuper.value.investments.find((el) => el.is_cash_option) ?? false
const alertBoxResponse = async () => {
  // Action investment change
  updatingSuper.value = true
  try {
    const superPayload = cloneObject(activeSuper.value)

    superPayload.allocation = requestedAllocation.value

    const recommendedInvestmentOption = superWidgetGraphData.value?.options.find(
      (el) => el.tag === 'recommended',
    )
    const optimisationText = recommendedInvestmentOption?.copy?.intro_1
      ? recommendedInvestmentOption?.copy.intro_1
      : 'We recommend you remain in your current investment option'

    const snakeCaseRequestedAllocation = convertArrayPayloadToSnakeCase(requestedAllocation.value)
    const intrafundAdvicePayload = {
      requested_investment_options: snakeCaseRequestedAllocation,
      previous_investment_options: activeSuper.value.investments,
      advice_stage: 4,
      super_account_number: superPayload.accountNumber,
      optimisationText: optimisationText,
      change_actioned: userRole.value === 'standard', // If the user is standard, the change is actioned automatically
    }
    await intrafundAdviceStore.updateIntrafundAdviceRequest(intrafundAdvicePayload)
    // Stopped the update from triggering immediately
    // will now trigger after the waiting period in the backend
    const snakeCaseSuperPayload = convertPayloadToSnakeCase(superPayload)
    snakeCaseSuperPayload['allocation'] = convertArrayPayloadToSnakeCase(superPayload.allocation)
    snakeCaseSuperPayload['auth0id'] = userData.value.auth0id
    const previousAllocation = activeSuper.value.investments
    const payload = {
      updated_super: snakeCaseSuperPayload,
      previous_investment_options: previousAllocation,
      selected_current_option: props.selectedCurrentOption,
    }
    await SuperFundService.sendIntrafundAdviceActionEmail(payload, superPayload.id)
    if (userRole.value === 'standard') {
      await SuperFundService.postIntrafundAdviceRequest(
        {
          member_id: userData.value.member_id,
          super_account_number: superPayload.account_number,
          advice_stage: 3,
          previous_investment_options: snakeCaseSuperPayload.allocation,
        },
        activeSuper.value.id,
      )
      // Immediately update super for standard user
      await superStore.updateSuperObject(activeSuper.value.id, {
        auth0id: userData.value.auth0id,
        account_number: superPayload.account_number,
        allocation: snakeCaseSuperPayload.allocation,
        chant_west_product_id: superPayload.chant_west_product_id,
        balance: superPayload.balance,
        data_source: 'user',
      })
      await fetchSuperInvestmentAdvice()
      // immediately add new intrafund advice request for standard user
      await SuperFundService.postIntrafundAdviceRequest(
        {
          member_id: userData.value.member_id,
          super_account_number: superPayload.account_number,
          advice_stage: 3,
          previous_investment_options: previousAllocation,
        },
        activeSuper.value.id,
      )
    } else {
      await moduleStatusesStore.fetchModuleStatuses()
      await superStore.fetchAllSupers()
    }
  } catch (error) {
    console.error(error)
  }

  await SuperFundService.logDisclaimerConsent(activeSuper.value.id)

  updatingSuper.value = false
  state.activeModal = 'clientSOA'
}
</script>

<style scoped></style>
