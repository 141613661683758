import { mod10Checksum } from '@/utilities.ts'
import { SuperProduct } from '@/types/WhiteLabelTypes.ts'
import { getValidator } from '@/composables/superfundValidators/validationHandler.ts'

export default (accountNumber: string, productArray: Array<SuperProduct>) => {
  const product = productArray.find((product) => {
    return product.code === accountNumber.substring(0, 3)
  })

  if (product) {
    return {
      chant_west_product_id: product.id,
      chant_west_product_code: product.code,
      fund_name: product.name,
      account_number: accountNumber,
    }
  }
  throw new Error('Invalid account number')
}
