const VALIDATOR_MAP = {
  COLONIAL_FIRST_STATE: () => import('./cfs.ts'),
  NESS_SUPER: () => import('./ness.ts'),
} as const

export type ValidatorId = keyof typeof VALIDATOR_MAP

export const getValidator = async (id: ValidatorId) => {
  const validator = VALIDATOR_MAP[id]
  if (!validator) {
    throw new Error(`Validator "${id}" not found`)
  }

  const module = await validator()
  return module.default
}
