import { TableDataType } from '@/types/CallcentrePortalTypes.ts'
import { userOnMobile } from '@/composables/userOnMobile.ts'
import { markRaw, ref } from 'vue'
import { GenericObject } from '@/types/GlobalTypes.ts'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import SuperFundService from '@/services/SuperFundService.ts'

type FinancialYearType = {
  id: number
  name: string
  maxContribution?: number
  nonConcessionalCap?: number
}

const last5FinancialYears = ref<Array<FinancialYearType>>()
const last5ConcessionalContributions = ref<GenericObject<number>>()

const concessionalContributions = (
  name: string,
  contributions: number,
  maxContributionForYear: number,
): TableDataType => {
  return {
    component: markRaw(BaseInput),
    value: contributions,
    enforceMinMax: true,
    max: maxContributionForYear,
    name: name,
    fieldType: 'currency',
  }
}
const maxContributions = (year: FinancialYearType, isConcessional: boolean): TableDataType => {
  return {
    component: markRaw(BaseInput),
    value: isConcessional ? year.maxContribution : year.nonConcessionalCap,
    name: year.id.toString(),
    fieldType: 'currency',
    disabled: true,
  }
}

export const getConcessionalHistoricalTableData = async (
  accountId: string,
  isConcessional: boolean,
): Promise<{
  tableData: Array<TableDataType>
  tableInputValue: GenericObject<number>
}> => {
  const promises = isConcessional
    ? await Promise.all([
        SuperFundService.getLastNumFinancialYears(5),
        SuperFundService.getLastFiveConcessionalContributions(accountId),
      ])
    : await Promise.all([
        SuperFundService.getLastNumFinancialYears(3),
        SuperFundService.getLastThreeNonConcessionalContributions(accountId),
      ])

  last5FinancialYears.value = promises[0].data.data
  last5ConcessionalContributions.value = promises[1].data.data.contributions
  if (!last5FinancialYears.value)
    return { tableData: [], tableInputValue: last5ConcessionalContributions.value }
  // Resetting the total available carry forward in computed to match value updates
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  const payload = [
    {
      component: 'Year',
      fieldType: 'heading',
    },
  ] as Array<TableDataType>

  if (!userOnMobile()) {
    payload.push(
      {
        component: isConcessional ? 'Concessional contributions' : 'Non concessional contributions',
        fieldType: 'heading',
      },
      {
        component: 'Annual contribution cap',
        fieldType: 'heading',
      },
    )
  }

  last5FinancialYears.value?.forEach((year: FinancialYearType) => {
    const historicalContribution =
      last5ConcessionalContributions.value && last5ConcessionalContributions.value[year.id]
        ? last5ConcessionalContributions.value[year.id]
        : 0
    payload.push({
      component: year.name,
      fieldType: 'html',
    })
    payload.push(
      concessionalContributions(
        year.id.toString(),
        historicalContribution,
        year.maxContribution as number,
      ),
    )
    payload.push(maxContributions(year, isConcessional))
  })
  return {
    tableData: payload,
    tableInputValue: last5ConcessionalContributions.value,
  }
}
