import { RouteRecordRaw } from 'vue-router'
import LoadingPage from '@/views/LoadingPage.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import CFSRetirementCalculator from '@/views/Clients/CFS/CFSRetirementCalculator.vue'
import CFSFAQs from '@/views/Clients/CFS/CFSFAQs.vue'
import { clientAuthGuard } from '@/router/clients/cfs/cfsRouteGuards.ts'
import ClientConfirmationDetails from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationDetails.vue'
import ClientConfirmationSMS from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationSMS.vue'
import ClientConfirmationChangeSuper from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationChangeSuper.vue'
import ClientConfirmationSOADisplay from '@/components/CFS/CFSClientConfirmationSteps/ClientConfirmationSOADisplay.vue'
import { isAuthenticated, userRole } from '@/lib/AuthenticatorPlugin.ts'
import HomepageContainer from '@/views/Otivo/HomepageContainer.vue'
import { getRootUrl } from '@/composables/getRootUrl.ts'
import ClientSetPassword from '@/components/CFS/CFSClientConfirmationSteps/ClientSetPassword.vue'
import ClientFeeConsentConfirmation from '@/components/CFS/CFSClientConfirmationSteps/ClientFeeConsentConfirmation.vue'
import ClientFeeConsentExpired from '@/components/CFS/CFSClientConfirmationSteps/ClientFeeConsentExpired.vue'

export default [
  {
    path: '/',
    name: 'home',
    component: HomepageContainer,
    beforeEnter: () => {
      if (isAuthenticated.value) {
        return getRootUrl(userRole.value)
      }
    },
  },
  {
    path: '/cfsadmin',
    redirect: '/admin',
  },
  {
    path: '/cfs/faq',
    name: 'cfsFAQ',
    component: CFSFAQs,
  },
  {
    path: '/client/signIn',
    name: 'clientSignIn',
    component: ClientConfirmationDetails,
    // beforeEnter: () => {
    //   if (import.meta.env.VITE_ENV_VAR === 'production') {
    //     if (window.location.host !== 'cfs.otivo.com')
    //       window.location.href = 'https://cfs.otivo.com/client/signIn'
    //   }
    // },
  },
  {
    path: '/client/sms',
    name: 'clientSMS',
    component: ClientConfirmationSMS,
  },
  {
    path: '/client/set-password',
    name: 'clientSetPassword',
    component: ClientSetPassword,
  },
  {
    path: '/client/disclaimer/:adviceTargetName?',
    name: 'clientDisclaimer',
    component: ClientFeeConsentConfirmation,
    beforeEnter: clientAuthGuard,
  },
  {
    path: '/client/renew',
    name: 'clientRenewDisclaimer',
    component: ClientFeeConsentConfirmation,
    beforeEnter: clientAuthGuard,
  },
  {
    path: '/client/expired',
    name: 'clientConsentExpired',
    component: ClientFeeConsentExpired,
    beforeEnter: clientAuthGuard,
  },
  {
    path: '/client/confirm',
    name: 'clientConfirm',
    component: ClientConfirmationChangeSuper,
    beforeEnter: clientAuthGuard,
  },
  {
    path: '/client/soa',
    name: 'clientSoa',
    component: ClientConfirmationSOADisplay,
    beforeEnter: clientAuthGuard,
  },
  {
    path: '/cfs/retirementCalculator',
    name: 'cfsRetirementCalculator',
    component: CFSRetirementCalculator,
    beforeEnter: () => {
      const trackingScript = document.createElement('script')
      trackingScript.src = import.meta.env.VITE_CFS_TRACKING_URL
      trackingScript.type = 'text/javascript'
      trackingScript.async = true
      document.getElementsByTagName('head')[0].appendChild(trackingScript)
    },
  },
] as Array<RouteRecordRaw>
