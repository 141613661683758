import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { computed } from 'vue'
import { AxiosResponse } from 'axios'
import OptimizeAdviceService from '@/services/OptimizeAdviceService.ts'

export default (): Promise<AxiosResponse> => {
  const superStore = useSuperStore()
  const activeSuperObject = computed(() => superStore.getActiveSuperObject)
  return OptimizeAdviceService.getChantWestContactInfo(
    activeSuperObject.value.chant_west_product_id,
  )
}
