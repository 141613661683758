<template>
  <section class="rounded-lg p-10 bg-white">
    <div class="flex flex-row justify-between items-center mb-10">
      <div class="flex flex-row gap-2.5 items-center justify-between">
        <button class="cursor-pointer" type="button" @click="() => handleChangeYear('prev')">
          <ArrowWide fill="otivo-blue" class="w-3.5 h-3.5 -rotate-90" />
        </button>
        <h6>{{ filterYear }}</h6>
        <button
          class="cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
          :disabled="filterYear == new Date().getFullYear().toString()"
          type="button"
          @click="() => handleChangeYear('next')">
          <ArrowWide fill="otivo-blue" class="w-3.5 h-3.5 rotate-90" />
        </button>
      </div>
      <div class="inline-flex gap-4">
        <BaseInput
          v-model:value.lazy="searchInput"
          class="min-w-[250px]"
          clearable
          name="search"
          @cleared="handleSearch"
          placeholder="Search by email" />
        <OtivoButton @click="handleSearch">Search</OtivoButton>
      </div>
    </div>
    <table class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
      <thead>
        <tr v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
          <th
            scope="col"
            v-for="header in headerGroup.headers"
            :key="header.id"
            :colSpan="header.colSpan"
            :class="{ 'cursor-pointer select-none': header.column.getCanSort() }"
            class="px-2.5 pt-5 pb-2.5 menu-1 text-white uppercase bg-blue-1 first:rounded-tl last:rounded-tr"
            @click="header.column.getToggleSortingHandler()?.($event)">
            <div class="flex gap-x-1">
              <template v-if="!header.isPlaceholder">
                <FlexRender :render="header.column.columnDef.header" :props="header.getContext()" />
                <span class="inline-flex" v-if="header.column.getCanSort()">
                  <ArrowLeftIcon
                    class="w-auto h-4 text-blue-2 rotate-90"
                    :class="{ '!text-blue-3': header.column.getIsSorted() == 'asc' }" />
                  <ArrowLeftIcon
                    class="w-auto h-4 text-blue-2 -rotate-90"
                    :class="{ '!text-blue-3': header.column.getIsSorted() == 'desc' }" />
                </span>
              </template>
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="total && !loading" class="divide-y divide-gray-200 dark:divide-neutral-700">
        <tr v-for="row in table.getRowModel().rows" :key="row.id">
          <td
            class="px-2.5 py-5 bg-white whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200 border-b border-b-grey-light"
            v-for="cell in row.getVisibleCells()"
            :key="cell.id">
            <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
          </td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr>
          <td v-if="loading" class="button-1 text-center py-5" colspan="7">Loading ...</td>
          <td v-else class="button-1 text-center py-5" colspan="7">No Data</td>
        </tr>
      </tbody>
    </table>
    <div>
      <div class="flex items-center gap-4 mt-10" v-if="total">
        <pagination
          class="!mt-0"
          :total-pages="Math.ceil(total / PAGE_SIZE)"
          @page-change="handlePageChange">
          <template #page-indicator="{ currentPage, totalPages }">
            <p class="flex items-center gap-1 paragraph-1 ml-4">
              <span class="button-2">Showing {{ currentPage }}</span>
              <span> out of {{ totalPages }} </span>
            </p>
          </template>
        </pagination>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {
  FlexRender,
  useVueTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
  SortingState,
} from '@tanstack/vue-table'
import { h, ref } from 'vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import Pagination from '@/components/Pagination.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import ArrowWide from '@/components/icons/ArrowWide.vue'

export type Person = {
  fullName: string
  clientId: string
  latestStatus: 'Completed' | 'Incomplete'
  q1: number
  q2: number
  q3: number
  q4: number
}

const sorting = ref<SortingState>([])
const columnHelper = createColumnHelper<Person>()
const searchInput = ref('')
const filterYear = ref(new Date().getFullYear().toString())
const PAGE_SIZE = 10
type Props = {
  users: Array<Person>
  total: number
  loading: boolean
}
const props = defineProps<Props>()
const emits = defineEmits<{
  (e: 'onSearch', value: string): void
  (e: 'onPaginate', value: number): void
  (e: 'onFilterYear', value: number): void
}>()

const columns = [
  columnHelper.accessor('fullName', {
    header: () => 'Name',
    cell: (info) => h('span', { class: 'button-2', innerHTML: info.getValue() }),
    enableSorting: false,
    size: 100,
  }),
  columnHelper.accessor('clientId', {
    cell: (info) => h('span', { class: 'paragraph-2', innerHTML: info.getValue() }),
    header: () => 'Email',
    enableSorting: false,
    size: 100,
  }),
  columnHelper.accessor('latestStatus', {
    cell: (info) =>
      h('span', {
        class: `paragraph-2 px-2 py-1 rounded-sm border ${
          info.getValue() == 'Incomplete'
            ? 'bg-grey-5 border-grey-4 text-grey-3'
            : 'bg-green-5 border-green-4 text-green-2'
        }`,
        innerHTML: info.getValue(),
      }),
    header: () => h('span', 'Latest Status'),
    enableSorting: false,
    size: 100,
  }),
  columnHelper.accessor('q1', {
    cell: (info) => h('span', { class: 'paragraph-2', innerHTML: info.getValue() || '-' }),
    enableSorting: false,
    header: 'Q1',
  }),
  columnHelper.accessor('q2', {
    cell: (info) => h('span', { class: 'paragraph-2', innerHTML: info.getValue() || '-' }),
    enableSorting: false,
    header: 'Q2',
  }),
  columnHelper.accessor('q3', {
    cell: (info) => h('span', { class: 'paragraph-2', innerHTML: info.getValue() || '-' }),
    enableSorting: false,
    header: 'Q3',
  }),
  columnHelper.accessor('q4', {
    cell: (info) => h('span', { class: 'paragraph-2', innerHTML: info.getValue() || '-' }),
    enableSorting: false,
    header: 'Q4',
  }),
]

const table = useVueTable({
  get data() {
    return props.users
  },
  columns,
  state: {
    get sorting() {
      return sorting.value
    },
  },
  onSortingChange: (updaterOrValue) => {
    sorting.value =
      typeof updaterOrValue === 'function' ? updaterOrValue(sorting.value) : updaterOrValue
  },
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
})

const handleChangeYear = (type: 'next' | 'prev') => {
  const newYear = type === 'next' ? +filterYear.value + 1 : +filterYear.value - 1
  filterYear.value = newYear.toString()
  emits('onFilterYear', +filterYear.value)
}

const handleSearch = () => {
  emits('onSearch', searchInput.value)
}

const handlePageChange = (page: number) => {
  emits('onPaginate', page)
}
</script>
