<template>
  <div id="portal-reporting" class="bg-blue-6 w-full h-full flex justify-center">
    <div class="flex w-100% gap-[40px] max-w-[1920px]">
      <div class="w-100% flex flex-col flex-grow">
        <div class="flex flex-row justify-between items-center">
          <div class="inline-flex space-x-10 items-center">
            <h4 class="text-blue-1">{{ title }}</h4>
          </div>
          <PeriodDatePicker
            @update-range="setSearchRange"
            :default-period="defaultSelectedPeriod" />
        </div>

        <div class="min-h-13">
          <div v-if="options.length" class="items-start py-10">
            <BaseDropDown
              class="block w-full"
              input-class="w-full min-w-80"
              :options="options"
              name="whitelabel_id"
              :default-value="defaultSelected"
              @selected="handleWhitelabelChange" />
          </div>
        </div>

        <div class="w-100%">
          <RouterView></RouterView>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import { useNavBarStore } from '@/store/pinia/NavBarStore.ts'
import { useCallcentreReportingStore } from '@/store/pinia/CallcentrePortalReportingStore.ts'
import PeriodDatePicker, { DateRange } from '@/components/PeriodDatePicker.vue'
import { useDebounceFn } from '@vueuse/core'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import BaseDropDown from '@/components/Inputs/BaseDropDown.vue'

const route = useRoute()
const sessionStore = useSessionStore()
const navBarStore = useNavBarStore()
const reportingStore = useCallcentreReportingStore()
const options = computed(() =>
  reportingStore.whitelabelOptions.map((option) => {
    return { label: option.name, value: option.id }
  }),
)

const sessionWhitelabel = computed(() => sessionStore.getWhitelabelData?.id)
const defaultSelected = computed(() => {
  return options.value.find((option) => option.value === sessionWhitelabel.value) || null
})
const defaultSelectedPeriod = computed(() => reportingStore.getDefaultSelectedPeriod)

const title = computed(() => route.meta.title)

onMounted(() => {
  // start fetching data
  reportingStore.getReportingMetrics()
  reportingStore.getWhitelabelOptions()
  setRoutesOverride()
})

const setRoutesOverride = async () => {
  // await navBarStore.overrideNavRoutes('reporting', false)
  navBarStore.setRouterComponents(['reporting'])
}

const handleWhitelabelChange = (option) => {
  reportingStore.setWhitelabelId(option.value)
}

const setSearchRange = useDebounceFn((range: DateRange) => {
  reportingStore.setSearchRange(range)
}, 500)
</script>

<style scoped lang="scss"></style>
